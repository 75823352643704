//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
import { useAppSelector } from "../../store/hooks";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import {
    CircularProgress,
    Grid,
    Skeleton,
    Slider,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    VTButton,
    VTCreatorBottomPanel,
    VTCreatorTimeline,
    VTSidePanel,
    VTStepper,
} from "@virtus-tech-repository/virtus-tech-repository";
import { useEffect, useRef, useState } from "react";
import { setCurrentDoubleClick, setCurrentDragging, setCurrentSidePanel } from "../../store/slices/current.slice";
import { ArrowForwardRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { Params, useNavigate, useParams } from "react-router";
import * as animationData from "../../assets/icons/vh_logo.json";
import { ArrowBackIosRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import { AddRounded, CancelRounded, SaveRounded, DeleteRounded } from "@mui/icons-material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { EOrganisation } from "../../models/organisations.model";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import VTSidePanelMediaDropContainer from "../components/SidePanelMediaDropContainer";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import {
    EDraggableHotspotId,
    EDroppableContainerId,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";

import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { IScenario, IScene, IStep } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { useDeleteSceneMutation, useGetScenesQuery, useReorderScenesMutation } from "../../services/scene.service";
import VTVideoTimeline from "../components/VTVideoTimeline";
import { DropPoint } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCreatorDropContainer";
import { v4 as uuid } from "uuid";
import { getSceneDataFromScenesArrBySceneId } from "../../utils/getSceneDataFromScenesArrBySceneId";
import { blankScene } from "../../helpers/scenarioDataGenerator";

import NoPreview from "../../assets/images/NoScenarioImage.svg";
import NoPreviewFile from "../../assets/images/NoFilesImage.svg";
import PassthroughPreview from "../../assets/images/PassthroughPreview.svg";
import Model3DPreview from "../../assets/images/Model3dPreview.svg";
import sleep from "../../utils/sleep";
import VTLocation from "../components/VTLocation";
import { getImageForSceneOrDefaultImageIfPreviewImageDoesntExist } from "../../utils/getImageForSceneOrDefaultImageIfPreviewImageDoesntExist";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    bottomDrawWidth: number;
    setBottomDrawWidth: (bottomDrawWidth: number) => void;
    timelineMode: "Scenes" | "Timeline" | "Stepper";
    setTimelineMode: (timelineMode: "Scenes" | "Timeline" | "Stepper") => void;
    mediaType: any;
    videoNextQuestion: IHotspot | undefined;
    setVideoCurrentState: (videoCurrentState: "Paused" | "Playing" | "Question" | undefined) => void;
    video: any;
    getNextQuestion: (time: number) => void;
    hotspots: IHotspot[] | undefined;
    videoCurrentTime: number;
    sortedQuestions: IHotspot[];
    setSortedQuestions: (sortedQuestions: IHotspot[]) => void;
    sceneLoading: string;
    setSceneLoading: (sceneLoading: string) => void;
    createScene: any;
    updateScene: any;
    createSceneLoading: any;
    setChangeSceneBuffer: any;
    videoTimelineLoading: string;
    setVideoTimelineLoading: (videoTimelineLoading: string) => void;
    setSettingsDialog: (settingDialog: string | undefined) => void;
    createHotspot: any;
    setVideoNextQuestion: any;
    videoCurrentState: any;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Panel for the creator timeline
export default function VTCreatorTimelineContainer({
    bottomDrawWidth,
    setBottomDrawWidth,
    timelineMode,
    setTimelineMode,
    mediaType,
    videoNextQuestion,
    videoCurrentState,
    setVideoCurrentState,
    video,
    getNextQuestion,
    hotspots = [],
    videoCurrentTime,
    sortedQuestions,
    setSortedQuestions,
    sceneLoading,
    setSceneLoading,
    createScene,
    updateScene,
    setSettingsDialog,
    createSceneLoading,
    setChangeSceneBuffer,
    videoTimelineLoading,
    setVideoTimelineLoading,
    setVideoNextQuestion,
    createHotspot,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stepperArray, setStepperArray] = useState<IScene[]>([]);
    const matches = useMediaQuery("(min-width:600px)");

    const [reorderScenes, { isLoading: sceneReorderLoading }] = useReorderScenesMutation();

    const [initialViewDialog, setInitialViewDialog] = useState<IScene | undefined>(undefined);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const {
        currentlyDragging,
        currentSidePanel,
        currentlyPreview,
        currentCreatorScenesTimelineShouldScrollToEnd,
        currentSidePannelDesiredWidth,
        currentHotspot,
    } = useAppSelector((state) => state.currentReducer);

    useEffect(() => {
        if (currentCreatorScenesTimelineShouldScrollToEnd) {
            window.setTimeout(() => {
                vtCreatorTimelineRef.current?.scrollBy({
                    left: 1000000,
                    top: 0,
                    behavior: "smooth",
                });
            }, 800);
        }
    }, [currentCreatorScenesTimelineShouldScrollToEnd]);

    const { organisation } = useAppSelector((state) => state.userReducer);

    const { data: scenes, isLoading: scenesLoading } = useGetScenesQuery(scenarioId ? scenarioId : "");

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (scenes) {
            let newStepperArray: IScene[] = [];

            scenes.map((scene: IScene) => scene.stepper !== undefined && newStepperArray.push(scene));

            if (newStepperArray.length === 0 && timelineMode === "Stepper") {
                setTimelineMode("Scenes");
            }

            setStepperArray(newStepperArray);
        }
    }, [scenes]);

    useEffect(() => {
        if (videoNextQuestion && hotspots && hotspots.length) {
            let tempSortedArray = hotspots.filter(
                (hotspotFilterElement: IHotspot) => hotspotFilterElement.type === EDraggableOptions.QUESTION,
            );
            tempSortedArray.sort((a: IHotspot, b: IHotspot) =>
                a.contents.preClickContent.question!.time > b.contents.preClickContent.question!.time ? 1 : -1,
            );
            setSortedQuestions(tempSortedArray);
        } else if (!videoNextQuestion && hotspots && hotspots.length && videoCurrentTime === 0) {
            getNextQuestion(0);
        }
    }, [videoNextQuestion, hotspots, scenes]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Function to get src and size images based on size

    const [deleteScene] = useDeleteSceneMutation();

    const vtCreatorTimelineRef = useRef<HTMLDivElement>(null);

    const timeOfCurrentlySelectedVideoQuestion = useState<number>();

    const [timeOfCurrentlySelectedQuestion, setTimeOfCurrentlySelectedQuestion] = useState<number>();

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Render the timeline with a panel
    return (
        <>
            {currentlyPreview ? (
                <>
                    {stepperArray.length > 1 && (
                        <VTStepper
                            stepperArray={stepperArray}
                            currentSceneId={sceneId!}
                            mediaType={mediaType}
                            handleItemClicked={(sceneId: string) => navigate(`/creator/${scenarioId}/${sceneId}`)}
                        />
                    )}
                </>
            ) : (
                <VTCreatorBottomPanel
                    leftSideMargin={currentSidePanel.currentWidth + 80}
                    sx={{ overflow: "hidden" }}
                    startingDrawHeight={130}
                    onDrawHeightChanged={setBottomDrawWidth}
                    minDrawerHeight={125}
                    maxDrawerHeight={180}
                >
                    {/*-------------------------------------------------------------------------------------*/}
                    {/* Dropbox overlay; rendered when media is grabbed from the side panel for images to be
dropped */}
                    {!currentHotspot.open &&
                        currentlyDragging?.mediaType !== EDraggableOptions.ANSWER &&
                        currentlyDragging?.mediaType !== EDraggableOptions.INFORMATION &&
                        currentlyDragging?.mediaType !== EDraggableOptions.COMPLETE &&
                        currentlyDragging?.mediaType !== EDraggableOptions.MOVEMENT &&
                        currentlyDragging?.mediaType !== EDraggableOptions.PROCEED &&
                        currentlyDragging?.mediaType !== EDraggableOptions.INTERFACE &&
                        currentlyDragging?.mediaType !== EDraggableOptions.IMAGE &&
                        currentlyDragging?.mediaType !== EDraggableOptions.AUDIO &&
                        currentlyDragging?.mediaType !== EDraggableOptions.TEXT_HOTSPOT &&
                        currentlyDragging?.mediaType !== EDraggableOptions.BULLET_POINTS_HOTSPOT && (
                            <VTSidePanelMediaDropContainer
                                mediaTypeBeingDragged={currentlyDragging?.mediaType}
                                location={DropPoint.DROP_TIMELINE}
                                bottomDrawWidth={bottomDrawWidth}
                                droppableId={EDroppableContainerId.SCENES_TIMELINE_DROP}
                                sx={{ padding: "10px", paddingLeft: "90px" }}
                            />
                        )}

                    {/* {currentlyDragging?.mediaType !== EDraggableOptions.ANSWER &&
                        currentlyDragging?.mediaType !== EDraggableOptions.INFORMATION &&
                        currentlyDragging?.mediaType !== EDraggableOptions.COMPLETE &&
                        currentlyDragging?.mediaType !== EDraggableOptions.MOVEMENT &&
                        currentlyDragging?.mediaType !== EDraggableOptions.PROCEED &&
                        currentlyDragging?.mediaType !== EDraggableOptions.INTERFACE && (
                            <VTSidePanelMediaDropContainer
                                mediaTypeBeingDragged={currentlyDragging?.mediaType}
                                location={DropPoint.DROP_TIMELINE}
                                bottomDrawWidth={bottomDrawWidth}
                                droppableId={EDroppableContainerId.SCENES_TIMELINE_DROP}
                            />
                        )} */}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* TimeLine Display */}
                    {/* -------------------------------------------------------------------------------------------- */}

                    {/* Added a throw error so that it could run on my device */}
                    <Grid container sx={{ overflow: "hidden" }}>
                        {currentlyDragging && currentlyDragging.mediaType === EDraggableOptions.SCENE ? (
                            <Grid item container xs={1} sx={{ position: "relative" }}>
                                <Grid item xs={12}>
                                    <VTButton
                                        sx={{
                                            height: "100%",
                                            width: "100%",
                                            borderRadius: 0,
                                            backgroundColor: timelineMode === "Scenes" ? "#696969" : "#292929",
                                            ":hover": {
                                                backgroundColor: timelineMode === "Scenes" ? "#797979" : "#393939",
                                            },
                                        }}
                                    >
                                        <DeleteRounded />
                                    </VTButton>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item container sx={{ width: "75px", height: bottomDrawWidth }}>
                                <Grid item xs={12}>
                                    <VTButton
                                        sx={{
                                            height: "100%",
                                            width: "100%",
                                            fontSize: "0.7rem",
                                            borderRadius: 0,
                                            backgroundColor: timelineMode === "Scenes" ? "#696969" : "#292929",
                                            ":hover": {
                                                backgroundColor: timelineMode === "Scenes" ? "#797979" : "#393939",
                                            },
                                        }}
                                        onClick={() => setTimelineMode("Scenes")}
                                    >
                                        Scenes
                                    </VTButton>
                                </Grid>
                                {(mediaType === EDraggableOptions.IMMERSIVE_VIDEO ||
                                    mediaType === EDraggableOptions.VIDEO) &&
                                    sortedQuestions.length > 0 &&
                                    hotspots.filter(
                                        (hotspotFilterElement: IHotspot) =>
                                            hotspotFilterElement.type === EDraggableOptions.QUESTION,
                                    ).length > 0 && (
                                        <Grid item xs={12}>
                                            <VTButton
                                                sx={{
                                                    width: "100%",
                                                    height: "100%",
                                                    fontSize: "0.7rem",
                                                    borderRadius: 0,
                                                    backgroundColor:
                                                        timelineMode === "Timeline" ? "#696969" : "#292929",
                                                    ":hover": {
                                                        backgroundColor:
                                                            timelineMode === "Timeline" ? "#797979" : "#393939",
                                                    },
                                                }}
                                                onClick={() => setTimelineMode("Timeline")}
                                            >
                                                Timeline
                                            </VTButton>
                                        </Grid>
                                    )}
                            </Grid>
                        )}

                        <Grid
                            item
                            container
                            sx={{
                                marginBottom: "10px",
                                height: bottomDrawWidth,
                                overflowX: "none",
                                position: "relative",
                                display: "flex",
                                flexGrow: 1,
                                width: "250px",
                            }}
                        >
                            {timelineMode === "Scenes" && (
                                <VTCreatorTimeline
                                    ref={vtCreatorTimelineRef}
                                    // This need to be added to stop jumpinh issue!
                                    sx={{ overflowX: "scroll" }}
                                    menuOffSet={{ height: bottomDrawWidth - 17, width: 60 }}
                                    isLoading={
                                        sceneLoading === "CreateScene" ||
                                        sceneLoading === "ReorderScenes" ||
                                        sceneReorderLoading ||
                                        scenesLoading
                                    }
                                    // sceneLoadingById={
                                    //     typeof sceneLoading === "string" && sceneLoading !== "CreateScene"
                                    //         ? sceneLoading
                                    //         : undefined
                                    // }
                                    sceneLoadingById={sceneLoading}
                                    activeSceneId={sceneId}
                                    scenes={
                                        scenes &&
                                        [...scenes!].sort((element1: IScene, element2: IScene) => {
                                            if (element1.order === undefined) {
                                                return 1;
                                            } else if (element2.order === undefined) {
                                                return -1;
                                            } else {
                                                return element2.order < element1.order ? 1 : -1;
                                            }
                                        })
                                    }
                                    onTimelineSceneNameEditSubmit={(sceneId: string, newSceneNameValue: string) => {
                                        setSceneLoading(sceneId);
                                        updateScene({ id: sceneId, name: newSceneNameValue });
                                    }}
                                    onSettingsClicked={(sceneId: string) => {
                                        setSettingsDialog(sceneId);
                                    }}
                                    onDuplicateSceneClicked={(sceneId: string) => {
                                        setSceneLoading("CreateScene");
                                        window.setTimeout(() => {
                                            vtCreatorTimelineRef.current?.scrollBy({
                                                left: 1000000,
                                                top: 0,
                                                behavior: "smooth",
                                            });
                                        }, 800);
                                        const sceneData = getSceneDataFromScenesArrBySceneId(sceneId, scenes!);

                                        createScene({
                                            scenarioId: scenarioId!,
                                            scene: {
                                                ...sceneData,
                                                name: sceneData.name + " copy",
                                                order: scenes!.length,
                                                id: uuid(),
                                            },
                                        });
                                    }}
                                    onDeleteSceneClicked={async (sceneId: string) => {
                                        // setSceneLoading(sceneId);
                                        // await sleep(150);

                                        if (window.confirm("Are you sure you want to delete this hotspot?")) {
                                            const scenesCopy = [...scenes!];
                                            const scenesWithoutDeletedItem = scenesCopy.filter(
                                                (scene: IScene) => scene.id !== sceneId,
                                            );
                                            const scenesOrderedWithoutDeletedItem = scenesWithoutDeletedItem.sort(
                                                (a: IScene, b: IScene) => {
                                                    return a.order! - b.order!;
                                                },
                                            );

                                            const scenesIdsInOrder: string[] = scenesOrderedWithoutDeletedItem.map(
                                                (scene: IScene, i: number) => {
                                                    return scene.id;
                                                },
                                            );

                                            await deleteScene({
                                                scenarioId: scenarioId!,
                                                sceneId: sceneId,
                                            });

                                            setSceneLoading("ReorderScenes");

                                            await reorderScenes({
                                                scenarioId: scenarioId,
                                                sceneIds: scenesIdsInOrder,
                                            });

                                            await sleep(250);

                                            setSceneLoading("");
                                        }
                                    }}
                                    onSceneStepperStatusChanged={(scene: IScene, addToStepper: boolean) => {
                                        if (!addToStepper) {
                                            createScene({
                                                scenarioId: scene.id,
                                                scene: { ...scene, stepper: undefined },
                                            });
                                        } else {
                                            updateScene({
                                                id: scene.id,
                                                stepper: {
                                                    skippable: true,
                                                },
                                            });
                                        }
                                    }}
                                    onAddSceneClick={() => {
                                        window.setTimeout(() => {
                                            vtCreatorTimelineRef.current?.scrollBy({
                                                left: 1000000,
                                                top: 0,
                                                behavior: "smooth",
                                            });
                                        }, 800);

                                        const newSceneId = uuid();
                                        if (organisation === EOrganisation.ECO_GOAL) {
                                            createScene({
                                                scenarioId: scenarioId!,
                                                scene: blankScene({
                                                    newSrc: "",
                                                    type: EDraggableOptions.DOCUMENT,
                                                    id: newSceneId,
                                                    orderId: scenes!.length,
                                                }),
                                            });
                                        } else {
                                            setSceneLoading("CreateScene");
                                            createScene({
                                                scenarioId: scenarioId!,
                                                scene: blankScene({
                                                    newSrc: "https://medias3.digi-sim-api.co.uk/app_defaults/default_360_image",
                                                    type: EDraggableOptions.IMMERSIVE_IMAGE,
                                                    id: newSceneId,
                                                    orderId: scenes!.length,
                                                }),
                                            });
                                            dispatch(
                                                setCurrentSidePanel({
                                                    currentTab: "360 Images",
                                                    currentWidth: currentSidePannelDesiredWidth,
                                                    option: "AddScene",
                                                }),
                                            );
                                        }
                                        setChangeSceneBuffer(newSceneId);
                                    }}
                                    onSceneClick={(sceneId: string) => {
                                        navigate(`/creator/${scenarioId}/${sceneId}`);
                                    }}
                                    getScenePreviewImageFn={function (sceneData: IScene) {
                                        return getImageForSceneOrDefaultImageIfPreviewImageDoesntExist(sceneData);
                                    }}
                                />
                            )}

                            {timelineMode === "Timeline" && (
                                <VTVideoTimeline
                                    sortedQuestions={sortedQuestions}
                                    videoNextQuestion={videoNextQuestion}
                                    handleTimelineItemClicked={(time: number) => {
                                        video.pause(time);
                                        setTimeOfCurrentlySelectedQuestion(time);
                                        setVideoCurrentState("Question");
                                        video.currentTime = time + 0.0001;
                                        getNextQuestion(time - 0.0001);
                                    }}
                                    timeOfCurrentlySelectedQuestion={timeOfCurrentlySelectedQuestion}
                                    bottomDrawWidth={bottomDrawWidth}
                                    videoTimelineLoading={videoTimelineLoading}
                                    setVideoTimelineLoading={setVideoTimelineLoading}
                                    video={video}
                                    videoCurrentTime={videoCurrentTime}
                                    createHotspot={createHotspot}
                                    setVideoNextQuestion={setVideoNextQuestion}
                                    setVideoCurrentState={setVideoCurrentState}
                                    setTimelineMode={setTimelineMode}
                                    videoCurrentState={videoCurrentState}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {/* -------------------------------------------------------------------------------------------- */}
                </VTCreatorBottomPanel>
            )}

            {initialViewDialog && (
                <VTLocation
                    currentScene={initialViewDialog}
                    open={initialViewDialog ? true : false}
                    setOpen={() => {
                        setInitialViewDialog(undefined);
                    }}
                    onSave={(id, newPosition) => {
                        setSceneLoading(id);
                        updateScene({ id, startLocation: [newPosition.x, newPosition.y, newPosition.z] });
                        // setSceneInitialView(id, newPosition);
                    }}
                />
            )}
        </>
    );
}

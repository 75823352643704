import {
    getImageDimensionsBeforeDrop,
    VTButton,
    VTCanvas,
    VTDialog,
    VTHTMLHotspot,
    VTRichTextEditor,
    VTSidePanelHotspotWrapper,
    VTTextField,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import currentSlice, {
    setCurrentHotspot,
    setCurrentRichTextEditorMode,
    setCurrentRichTextEditorNormalModeId,
    setCurrentRichTextEditorPresentationModeDataToDropObject,
    setCurrentRichTextEditorPresentationModeDropOffsetsCoords,
    setCurrentSidePanel,
    setEditHotspotDialogIsCurrentlyOpen,
    setEditHotspotDialogMediaTransfer,
    setEditHotspotDialogTabCurrentlyOpen,
} from "../../store/slices/current.slice";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import {
    Grid,
    MenuItem,
    Paper,
    Select,
    Skeleton,
    Slider,
    Switch,
    SxProps,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { InfoRounded, KeyboardDoubleArrowUpRounded, RadioButtonCheckedRounded } from "@mui/icons-material";

import { Params, useParams } from "react-router-dom";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Color, CompactPicker, SketchPicker } from "react-color";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { EditHotSpotDialogTab } from "../../models/current.models";
import VTImageDropper from "./VTImageDropper";
import VTVideoDropper from "./VTVideoDropper";
import VTAudioDropper from "./VTAudioDropper";
import { getDurationOfAudioInSeconds } from "../../utils/getAudioClipDuration";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { useUpdateAllScenarioMediaMutation } from "../../services/media.service";

import GridOnIcon from "@mui/icons-material/GridOn";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import BorderStyleIcon from "@mui/icons-material/BorderStyle";
import {
    EContentDataType,
    IContentData,
    IHotspot,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { IScenario, IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { useUpdateScenarioMutation } from "../../services/scenario.service";
import {
    HotspotApi,
    useCreateGroupHotspotMutation,
    useGetGroupHotspotsQuery,
    useGetHotspotsQuery,
    useUpdateGroupHotspotMutation,
    useUpdateHotspotMutation,
} from "../../services/hotspot.service";
import { useGetScenesQuery } from "../../services/scene.service";
import { EDroppableContainerId } from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import { v4 as uuid } from "uuid";
import { Droppable } from "react-beautiful-dnd";
import VTHotspotPreview from "./VTHotspotPreview";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import { CancelRounded, VisibilityRounded, VisibilityOffRounded } from "@mui/icons-material";
import VTIconDropDown from "./VTIconDropDown";

import DropImage from "../../assets/images/image_drop.svg";
import DropVideo from "../../assets/images/video_drop.svg";
import DropAudio from "../../assets/images/audio_drop.svg";
import HomeSettingsItem from "./HomeSettingsItem";

import "../../styles/richTextEditorPresentationModeStyleOverrides.css";
import VTRichTextEditorPresentationMode, {
    IVTExposedRichTextEditorPresentationModeMethods,
} from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTHotspotContentCreator/VTRichTextEditorPresentationMode";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import useMediaUploader from "../../hooks/useMediaUploader";
import { IconName, IconWrapper } from "./IconWrapper";
import { checkIconExistsInIconWrapper } from "../../utils/checkIconExists";
export default function VTHotspotEditDialog({
    sx,
    videoCurrentState,
    videoNextQuestion,
}: {
    sx: SxProps;
    videoCurrentState: string | undefined;
    videoNextQuestion?: IHotspot;
}) {
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const [mediaId, mediaUploadLoading, uploadComplete, handleUpload] = useMediaUploader(dispatch);

    const [updateHotspot] = useUpdateHotspotMutation();
    const [updateQuestionHotspot] = useUpdateGroupHotspotMutation();
    const [createQuestionHotspot] = useCreateGroupHotspotMutation();

    const richTextEditorPresentationModeRef = useRef<IVTExposedRichTextEditorPresentationModeMethods>(null);

    const {
        currentScenario,
        currentScene,
        currentHotspot,
        currentQuestion,
        currentSidePanel,
        editHotspotDialogMediaTransfer,
        currentlyDragging,
        currentRichTextEditorPresentationModeDataToDropObject,
        currentSidePannelDesiredWidth,
        currentRichTextEditorPresentationModeDropOffsetsCoords,
    } = useAppSelector((state) => state.currentReducer);

    const currentlyDraggingObj = {
        src: currentlyDragging?.src ? currentlyDragging.src : "",
        preview: currentlyDragging?.preview ? currentlyDragging.preview : "",
        mediaType: "images",
        // mediaType: (currentlyDragging?.mediaType ? currentlyDragging.mediaType : "") as EDraggableOptions
    };
    const [updateAllScenarioMedia] = useUpdateAllScenarioMediaMutation();

    const openDialog = currentHotspot.currentHotspot !== undefined && currentHotspot.open;

    const [transformCheckedPre, setTransformCheckedPre] = useState(false);
    const [transformCheckedPost, setTransformCheckedPost] = useState(false);

    const [currentEditTab, setCurrentEditTab] = useState<EditHotSpotDialogTab>("content");
    const [currentPrePostTab, setCurrentPrePostTab] = useState<"pre" | "post">("pre");

    const [preClickTitle, setPreClickTitle] = useState<string>("");
    const [postClickTitle, setPostClickTitle] = useState<string>("");
    const [preClickIcon, setPreClickIcon] = useState<string | undefined>("");
    const [postClickIcon, setPostClickIcon] = useState<string | undefined>("");
    const [preClickContent, setPreClickContent] = useState<string | IContentData[]>("");
    const [postClickContent, setPostClickContent] = useState<string | IContentData[]>("");
    const [prePresentationModeHotspotWidthHeight, setPrePresentationModeHotspotWidthHeight] = useState<{
        w: number;
        h: number;
    }>({
        w: 350,
        h: 220,
    });
    const [postPresentationModeHotspotWidthHeight, setPostPresentationModeHotspotWidthHeight] = useState<{
        w: number;
        h: number;
    }>({
        w: 350,
        h: 220,
    });

    const [preClickBackgroundColour, setPreClickBackgroundColour] = useState<Color>("");
    const [postClickBackgroundColour, setPostClickBackgroundColour] = useState<Color>("");
    const [preClickTextColour, setPreClickTextColour] = useState<Color>("");
    const [postClickTextColour, setPostClickTextColour] = useState<Color>("");
    const [preClickBorderColour, setPreClickBorderColour] = useState<Color>("");
    const [postClickBorderColour, setPostClickBorderColour] = useState<Color>("");
    const [preClickScale, setPreClickScale] = useState<number>(1);
    const [postClickScale, setPostClickScale] = useState<number>(1);

    const [preHoverClickBackgroundColour, setPreHoverClickBackgroundColour] = useState<Color>("");
    const [postHoverClickBackgroundColour, setPostHoverClickBackgroundColour] = useState<Color>("");
    const [preHoverClickTextColour, setPreHoverClickTextColour] = useState<Color>("");
    const [postHoverClickTextColour, setPostHoverClickTextColour] = useState<Color>("");
    const [preHoverClickBorderColour, setPreHoverClickBorderColour] = useState<Color>("");
    const [postHoverClickBorderColour, setPostHoverClickBorderColour] = useState<Color>("");
    const [preHoverClickScale, setPreHoverClickScale] = useState<number>(1);
    const [postHoverClickScale, setPostHoverClickScale] = useState<number>(1);

    const [actionSceneId, setActionSceneId] = useState<string>("");
    const [actionSceneDelay, setActionSceneDelay] = useState<number>(0);
    const [actionFeedbackImage, setActionFeedbackImage] = useState<string>("");
    const [actionFeedbackVideo, setActionFeedbackVideo] = useState<string>("");
    const [actionFeedbackAudio, setActionFeedbackAudio] = useState<string>("");
    const [actionFeedbackInput, setActionFeedbackInput] = useState<string>("");
    const [actionNavigate, setActionNavigate] = useState<string>("");
    const [actionCloseable, setActionCloseable] = useState<boolean>(false);
    const [actionProceed, setActionProceed] = useState<boolean>(false);

    const { data: questionHotspots } = useGetGroupHotspotsQuery(videoNextQuestion ? videoNextQuestion.id : "");
    const { data: hotspots } = useGetHotspotsQuery(currentScene.id);

    const [actionFeedbackDelay, setActionFeedbackDelay] = useState<number>(0);
    const { data: scenes } = useGetScenesQuery(scenarioId ? scenarioId : "");

    const matches = useMediaQuery("(min-width:800px)");

    const [actionRenderConditions, setActionRenderConditions] = useState<string[]>([]);

    const [preEditorMode, setPreEditorMode] = useState<"presentation mode" | "normal mode">("normal mode");

    useEffect(() => {
        dispatch(setCurrentRichTextEditorMode(preEditorMode));

        return () => {
            // when rte is unmounted make sure to set editor mode back to default undefined
            dispatch(setCurrentRichTextEditorMode(undefined));

            dispatch(setCurrentRichTextEditorPresentationModeDropOffsetsCoords(undefined));
            dispatch(setCurrentRichTextEditorPresentationModeDataToDropObject(undefined));
        };
    }, [preEditorMode]);

    const [postEditorMode, setPostEditorMode] = useState<"presentation mode" | "normal mode">("normal mode");

    useEffect(() => {
        dispatch(setCurrentRichTextEditorMode(postEditorMode));
    }, [postEditorMode]);

    let richTextEditorDropMsgAndImage: undefined | { text: string; svgImage: any };

    if (currentlyDragging?.mediaType === EDraggableOptions.IMAGE) {
        richTextEditorDropMsgAndImage = {
            text: "Drop 2D Image",
            svgImage: DropImage,
        };
    } else if (currentlyDragging?.mediaType === EDraggableOptions.VIDEO) {
        richTextEditorDropMsgAndImage = {
            text: "Drop 2D Video",
            svgImage: DropVideo,
        };
    } else if (currentlyDragging?.mediaType === EDraggableOptions.AUDIO) {
        richTextEditorDropMsgAndImage = {
            text: "Drop 2D Audio",
            svgImage: DropAudio,
        };
    } else {
        richTextEditorDropMsgAndImage = undefined;
    }

    const [hotspotPreview, setHotspotPreview] = useState<boolean>(false);
    const [hotspotOpen, setHotspotOpen] = useState<boolean>(false);

    function getHotspotPreviewPxWidth(): number {
        return (currentPrePostTab === "pre" && preEditorMode === "normal mode") ||
            (currentPrePostTab === "post" && postEditorMode === "normal mode")
            ? 455
            : 310;
    }

    useEffect(() => {
        if (openDialog) {
            if (currentSidePanel.currentTab) {
                dispatch(
                    setCurrentSidePanel({
                        currentTab: "Images",
                        currentWidth: currentSidePannelDesiredWidth,
                        option: undefined,
                    }),
                );
            }

            dispatch(setEditHotspotDialogIsCurrentlyOpen(true));
        } else {
            setCurrentEditTab("content");
            // TODO: investigate why this is fixing it (race condiiton with redux action dispatches?)
            dispatch(setEditHotspotDialogIsCurrentlyOpen(false));
            //dispatch(setEditHotspotDialogTabCurrentlyOpen(undefined)); // as edit hotspot dialog is closed, no tab within it can be open now
            dispatch(
                setCurrentSidePanel({
                    currentTab: undefined,
                    currentWidth: 0,
                    option: undefined,
                }),
            );
            setHotspotPreview(false);
            setHotspotOpen(false);
            setCurrentPrePostTab("pre");
        }
    }, [openDialog]);

    useEffect(() => {
        if (!openDialog) {
            setPreClickContent("");
            setPostClickContent(""); // needs testing
        }
    }, [openDialog]);

    useEffect(() => {
        if (openDialog) {
            dispatch(setEditHotspotDialogTabCurrentlyOpen(currentEditTab));
        } else {
            dispatch(setEditHotspotDialogTabCurrentlyOpen(undefined));
        }
    }, [currentEditTab, openDialog]);

    function handleAudioDropped(audioItem: string) {
        setActionFeedbackAudio(audioItem);
        if (audioItem !== "") {
            const storedAudio = new Audio(audioItem);
            storedAudio.addEventListener("loadedmetadata", function () {
                if (storedAudio.duration) {
                    setActionFeedbackDelay(Math.ceil(storedAudio.duration));
                    setActionSceneDelay(Math.ceil(storedAudio.duration));
                }
            });
        }
    }

    useEffect(() => {
        if (editHotspotDialogMediaTransfer !== undefined) {
            if (currentEditTab === "actions") {
                if (editHotspotDialogMediaTransfer.type === EDraggableOptions.IMAGE) {
                    setActionFeedbackImage(editHotspotDialogMediaTransfer.src);
                } else if (editHotspotDialogMediaTransfer.type === EDraggableOptions.VIDEO) {
                    setActionFeedbackVideo(
                        editHotspotDialogMediaTransfer.src.split(".mp4")[0] +
                            "/" +
                            editHotspotDialogMediaTransfer.src.split(".mp4")[0].split("video/")[1] +
                            ".mp4",
                    );
                } else if (editHotspotDialogMediaTransfer.type === EDraggableOptions.AUDIO) {
                    handleAudioDropped(editHotspotDialogMediaTransfer.src);
                }
            } else {
                //#TODO; add on to the end of the content for pre and post when double clicking on the side panel
                setPreClickContent(
                    preClickContent +
                        ` <img src=${editHotspotDialogMediaTransfer.src} loading="lazy" style="object-fit: cover; border-radius: 5px; height: 200px; width: 200px; margin: 0px; position: relative;" draggable="true" id="I_50150" class="rte-image">`,
                );
            }
            dispatch(setEditHotspotDialogMediaTransfer(undefined));
        }
    }, [editHotspotDialogMediaTransfer]);

    useEffect(() => {
        if (currentHotspot && currentHotspot.currentHotspot) {
            // console.log("re-setting state");
            setPreClickTitle(
                currentHotspot?.currentHotspot.contents.preClickContent.title
                    ? currentHotspot?.currentHotspot.contents.preClickContent.title
                    : "",
            );
            setPostClickTitle(
                currentHotspot?.currentHotspot.contents.postClickContent?.title
                    ? currentHotspot?.currentHotspot.contents.postClickContent.title
                    : "",
            );
            setPreClickIcon(
                currentHotspot?.currentHotspot.contents.preClickContent.icon
                    ? currentHotspot?.currentHotspot.contents.preClickContent.icon
                    : "",
            );
            setPostClickIcon(
                currentHotspot?.currentHotspot.contents.postClickContent?.icon
                    ? currentHotspot?.currentHotspot.contents.postClickContent.icon
                    : "",
            );
            setPreClickContent(
                currentHotspot?.currentHotspot.contents.preClickContent?.contentData
                    ? currentHotspot?.currentHotspot.contents.preClickContent.contentData
                    : "",
            );
            setPrePresentationModeHotspotWidthHeight(
                currentHotspot?.currentHotspot.contents.preClickContent?.presentationModeContentWidthHeight
                    ? currentHotspot?.currentHotspot.contents.preClickContent?.presentationModeContentWidthHeight
                    : {
                          w: 350,
                          h: 220,
                      },
            );

            setPostPresentationModeHotspotWidthHeight(
                currentHotspot?.currentHotspot.contents.postClickContent?.presentationModeContentWidthHeight
                    ? currentHotspot?.currentHotspot.contents.postClickContent?.presentationModeContentWidthHeight
                    : {
                          w: 350,
                          h: 220,
                      },
            );

            setPostClickContent(
                currentHotspot?.currentHotspot.contents.postClickContent?.contentData
                    ? currentHotspot?.currentHotspot.contents.postClickContent.contentData
                    : "",
            );

            setPreClickBackgroundColour(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.colours?.background
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.colours?.background
                    : "",
            );
            setPostClickBackgroundColour(
                currentHotspot?.currentHotspot.styles?.postClickStyles?.colours?.background
                    ? currentHotspot?.currentHotspot.styles?.postClickStyles?.colours?.background
                    : "",
            );
            setPreClickTextColour(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.colours?.text
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.colours?.text
                    : "",
            );
            setPostClickTextColour(
                currentHotspot?.currentHotspot.styles?.postClickStyles?.colours?.text
                    ? currentHotspot?.currentHotspot.styles?.postClickStyles?.colours?.text
                    : "",
            );

            setPreClickBorderColour(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.colours?.border
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.colours?.border
                    : "",
            );
            setPostClickBorderColour(
                currentHotspot?.currentHotspot.styles?.postClickStyles?.colours?.border
                    ? currentHotspot?.currentHotspot.styles?.postClickStyles?.colours?.border
                    : "",
            );
            setPreClickScale(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.scale
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.scale
                    : 1,
            );
            setPostClickScale(
                currentHotspot?.currentHotspot.styles?.postClickStyles?.scale
                    ? currentHotspot?.currentHotspot.styles?.postClickStyles?.scale
                    : 1,
            );

            setPreHoverClickBackgroundColour(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.hover?.colours?.background
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.hover?.colours?.background
                    : "",
            );
            setPostHoverClickBackgroundColour(
                currentHotspot?.currentHotspot.styles?.postClickStyles?.hover?.colours?.background
                    ? currentHotspot?.currentHotspot.styles?.postClickStyles?.hover?.colours?.background
                    : "",
            );
            setPreHoverClickTextColour(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.hover?.colours?.text
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.hover?.colours?.text
                    : "",
            );
            setPostHoverClickTextColour(
                currentHotspot?.currentHotspot.styles?.postClickStyles?.hover?.colours?.text
                    ? currentHotspot?.currentHotspot.styles?.postClickStyles?.hover?.colours?.text
                    : "",
            );

            setPreHoverClickBorderColour(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.hover?.colours?.border
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.hover?.colours?.border
                    : "",
            );
            setPostHoverClickBorderColour(
                currentHotspot?.currentHotspot.styles?.postClickStyles?.hover?.colours?.border
                    ? currentHotspot?.currentHotspot.styles?.postClickStyles?.hover?.colours?.border
                    : "",
            );
            setPreHoverClickScale(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.hover?.scale
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.hover?.scale
                    : 1,
            );
            setPostHoverClickScale(
                currentHotspot?.currentHotspot.styles?.postClickStyles?.hover?.scale
                    ? currentHotspot?.currentHotspot.styles?.postClickStyles?.hover?.scale
                    : 1,
            );

            setActionSceneId(
                currentHotspot?.currentHotspot.actions?.movement?.sceneId
                    ? currentHotspot?.currentHotspot.actions?.movement?.sceneId
                    : "",
            );
            setActionSceneDelay(
                currentHotspot?.currentHotspot.actions?.movement?.delay
                    ? currentHotspot?.currentHotspot.actions?.movement?.delay
                    : 0,
            );
            setActionFeedbackImage(
                currentHotspot?.currentHotspot.actions?.appFeedback?.image
                    ? currentHotspot?.currentHotspot.actions?.appFeedback?.image
                    : "",
            );
            setActionFeedbackVideo(
                currentHotspot?.currentHotspot.actions?.appFeedback?.video
                    ? currentHotspot?.currentHotspot.actions?.appFeedback?.video
                    : "",
            );
            setActionFeedbackInput(
                currentHotspot?.currentHotspot.actions?.appFeedback?.input
                    ? currentHotspot?.currentHotspot.actions?.appFeedback?.input
                    : "",
            );
            setActionNavigate(
                currentHotspot?.currentHotspot.actions?.navigate
                    ? currentHotspot?.currentHotspot.actions?.navigate
                    : "",
            );

            setActionFeedbackAudio(
                currentHotspot?.currentHotspot.actions?.appFeedback?.audio
                    ? currentHotspot?.currentHotspot.actions?.appFeedback?.audio
                    : "",
            );
            setActionCloseable(
                currentHotspot?.currentHotspot.actions?.closeable
                    ? currentHotspot?.currentHotspot.actions?.closeable
                    : false,
            );
            setActionProceed(
                currentHotspot?.currentHotspot.actions?.appFeedback?.proceed
                    ? currentHotspot?.currentHotspot.actions?.appFeedback.proceed
                    : false,
            );
            setActionFeedbackDelay(
                currentHotspot?.currentHotspot.actions?.appFeedback?.delay
                    ? currentHotspot?.currentHotspot.actions?.appFeedback.delay
                    : 0,
            );

            setActionRenderConditions(
                currentHotspot?.currentHotspot.actions?.renderCondition
                    ? currentHotspot?.currentHotspot.actions?.renderCondition
                    : [],
            );
            setTransformCheckedPre(
                currentHotspot?.currentHotspot.styles?.preClickStyles?.transform
                    ? currentHotspot?.currentHotspot.styles?.preClickStyles?.transform
                    : false,
            );
            setTransformCheckedPost(
                currentHotspot?.currentHotspot.styles?.postClickStyles?.transform
                    ? currentHotspot?.currentHotspot.styles?.postClickStyles?.transform
                    : false,
            );
        }
    }, [currentHotspot, openDialog]);

    const [preRichTextEditorInitialContent, setPreRichTextEditorInitialContent] = useState<string | IContentData[]>("");

    function getHotspotContentDataFromNormalModeOrPresentationMode(
        preClickOrPostClick: "preclick" | "postclick",
    ): string | IContentData[] | undefined {
        if (preClickOrPostClick === "preclick") {
            return preEditorMode === "normal mode"
                ? Array.isArray(preRichTextEditorInitialContent) !== Array.isArray(preClickContent)
                    ? preRichTextEditorInitialContent
                    : preClickContent
                : Array.isArray(preClickContent) && preClickContent.length! > 0
                ? preClickContent
                : undefined;
        } else {
            return postEditorMode === "normal mode"
                ? Array.isArray(postRichTextEditorInitialContent) !== Array.isArray(postClickContent)
                    ? postRichTextEditorInitialContent
                    : postClickContent
                : Array.isArray(postClickContent) && postClickContent.length! > 0
                ? postClickContent
                : undefined;
        }
    }

    // check if there's pre-existing normal mode or presentation mode data and switch to correct mode.
    useEffect(() => {
        if (Array.isArray(preRichTextEditorInitialContent)) {
            setPreEditorMode("presentation mode");
        } else if (typeof preRichTextEditorInitialContent === "string" && preRichTextEditorInitialContent !== "") {
            setPreEditorMode("normal mode");
        }
    }, [preRichTextEditorInitialContent]);

    const [postRichTextEditorInitialContent, setPostRichTextEditorInitialContent] = useState<string | IContentData[]>(
        "",
    );

    useEffect(() => {
        if (Array.isArray(postRichTextEditorInitialContent)) {
            setPostEditorMode("presentation mode");
        } else if (typeof postRichTextEditorInitialContent === "string" && postRichTextEditorInitialContent !== "") {
            setPostEditorMode("normal mode");
        }
    }, [postRichTextEditorInitialContent]);

    const preClickNormalModePresentationModeContentCacheRef = useRef<{
        normalModeContent: string | null;
        presentationModeContent: IContentData[] | null;
    }>({
        normalModeContent: null,
        presentationModeContent: null,
    });

    const postClickNormalModePresentationModeContentCacheRef = useRef<{
        normalModeContent: string | null;
        presentationModeContent: IContentData[] | null;
    }>({
        normalModeContent: null,
        presentationModeContent: null,
    });

    // const [canCache, setCanCache] = useState<boolean>(false);

    // useEffect(() => {
    //     if (openDialog) {
    //         setCanCache(true);
    //     } else {
    //         setCanCache(false);
    //     }
    // }, [openDialog]);

    const [initialPreEditorMode, setInitialPreEditorMode] = useState<"normal mode" | "presentation mode">();
    const [initialPostEditorMode, setInitialPostEditorMode] = useState<"normal mode" | "presentation mode">();

    // const canUseInitialPreEditorModeRef = useRef<boolean>(false);

    useEffect(() => {
        preClickNormalModePresentationModeContentCacheRef.current = {
            normalModeContent: null,
            presentationModeContent: null,
        };

        postClickNormalModePresentationModeContentCacheRef.current = {
            normalModeContent: null,
            presentationModeContent: null,
        };

        // console.log("pre click cache ref", preClickNormalModePresentationModeContentCacheRef.current);

        if (openDialog) {
            // window.setTimeout(() => {
            //     setCurrentPrePostTab("pre");
            // });
            setInitialPreEditorMode(
                typeof preClickContent === "string" || typeof preClickContent === undefined
                    ? "normal mode"
                    : "presentation mode",
            );

            setInitialPostEditorMode(
                typeof postClickContent === "string" || typeof postClickContent === undefined
                    ? "normal mode"
                    : "presentation mode",
            );
        } else {
            setInitialPreEditorMode(undefined);
            setInitialPostEditorMode(undefined);
        }
    }, [openDialog]);

    useEffect(() => {
        if (initialPreEditorMode) {
            setPreEditorMode(initialPreEditorMode);
        }
    }, [initialPreEditorMode]);

    useEffect(() => {
        setInitialPreEditorMode(undefined);
    }, [preEditorMode]);

    // normal mode and presentation mode caching logic inside this useEffect hook
    useEffect(() => {
        if (currentPrePostTab === "pre") {
            if (preEditorMode === "normal mode") {
                // console.log("rte cache", "entering normal mode");

                // if pre click content was last an array, update cache which holds presentation mode data to be new preClickContent array
                if (Array.isArray(preClickContent)) {
                    preClickNormalModePresentationModeContentCacheRef.current.presentationModeContent = preClickContent;
                    // console.log("what's it setting", preClickContent);
                }

                const normalModePreRichTextEditorInitialContentToSet =
                    preClickNormalModePresentationModeContentCacheRef.current.normalModeContent !== null
                        ? preClickNormalModePresentationModeContentCacheRef.current.normalModeContent
                        : typeof preClickContent !== "string"
                        ? `<divclass="rte-container"></div>`
                        : preClickContent;

                setPreRichTextEditorInitialContent(normalModePreRichTextEditorInitialContentToSet);
            }

            if (preEditorMode === "presentation mode") {
                // console.log("rte cache", "entering presentation mode");

                // if pre click content was last string update cache which holds normal mdoe data to be new preClickContent string
                if (typeof preClickContent === "string") {
                    preClickNormalModePresentationModeContentCacheRef.current.normalModeContent = preClickContent;
                }

                const presentationModePreRichTextEditorInitialContentToSet =
                    Array.isArray(preClickNormalModePresentationModeContentCacheRef.current.presentationModeContent) &&
                    preClickNormalModePresentationModeContentCacheRef.current.presentationModeContent.length > 0
                        ? preClickNormalModePresentationModeContentCacheRef.current.presentationModeContent
                        : Array.isArray(preClickContent)
                        ? preClickContent
                        : [];

                setPreRichTextEditorInitialContent(presentationModePreRichTextEditorInitialContentToSet);
            }
        } else if (currentPrePostTab === "post") {
            if (postEditorMode === "normal mode") {
                // console.log("rte cache", "entering normal mode post");

                // if pre click content was last an array, update cache which holds presentation mode data to be new preClickContent array
                if (Array.isArray(postClickContent)) {
                    postClickNormalModePresentationModeContentCacheRef.current.presentationModeContent =
                        postClickContent;
                }

                const normalModePostRichTextEditorInitialContentToSet =
                    postClickNormalModePresentationModeContentCacheRef.current.normalModeContent !== null
                        ? postClickNormalModePresentationModeContentCacheRef.current.normalModeContent
                        : typeof postClickContent !== "string"
                        ? `<divclass="rte-container"></div>`
                        : postClickContent;

                setPostRichTextEditorInitialContent(normalModePostRichTextEditorInitialContentToSet);
            }

            if (postEditorMode === "presentation mode") {
                // console.log("rte cache", "entering presentation mode post");

                // if pre click content was last string update cache which holds normal mdoe data to be new preClickContent string
                if (typeof postClickContent === "string") {
                    postClickNormalModePresentationModeContentCacheRef.current.normalModeContent = postClickContent;
                }

                const presentationModePostRichTextEditorInitialContentToSet =
                    Array.isArray(postClickNormalModePresentationModeContentCacheRef.current.presentationModeContent) &&
                    postClickNormalModePresentationModeContentCacheRef.current.presentationModeContent.length > 0
                        ? postClickNormalModePresentationModeContentCacheRef.current.presentationModeContent
                        : Array.isArray(postClickContent)
                        ? postClickContent
                        : [];

                setPostRichTextEditorInitialContent(presentationModePostRichTextEditorInitialContentToSet);
            }
        }
    }, [preEditorMode, postEditorMode, currentPrePostTab, currentHotspot]);

    function createNewHotspot(id?: string) {
        return {
            id: id ? id : currentHotspot.currentHotspot!.id,
            location: currentHotspot?.currentHotspot!.location,
            type: currentHotspot?.currentHotspot!.type,
            styles: {
                preClickStyles: {
                    colours: {
                        background: preClickBackgroundColour !== "" ? preClickBackgroundColour : undefined,
                        text: preClickTextColour !== "" ? preClickTextColour : undefined,
                        border: preClickBorderColour !== "" ? preClickBorderColour : undefined,
                    },
                    scale: preClickScale !== 1 ? preClickScale : undefined,
                    transform: transformCheckedPre ? transformCheckedPre : undefined,
                    hover:
                        preHoverClickScale ||
                        preHoverClickBackgroundColour ||
                        preHoverClickTextColour ||
                        preHoverClickBorderColour
                            ? {
                                  scale: preHoverClickScale !== 1 ? preHoverClickScale : undefined,
                                  colours: {
                                      background:
                                          preHoverClickBackgroundColour !== ""
                                              ? preHoverClickBackgroundColour
                                              : undefined,
                                      text: preHoverClickTextColour !== "" ? preHoverClickTextColour : undefined,
                                      border: preHoverClickBorderColour !== "" ? preHoverClickBorderColour : undefined,
                                  },
                              }
                            : undefined,
                },
                postClickStyles: {
                    colours: {
                        background: postClickBackgroundColour !== "" ? postClickBackgroundColour : undefined,
                        text: postClickTextColour !== "" ? postClickTextColour : undefined,
                        border: postClickBorderColour !== "" ? postClickBorderColour : undefined,
                    },
                    scale: postClickScale !== 1 ? postClickScale : undefined,
                    transform: transformCheckedPost ? transformCheckedPost : undefined,
                    hover:
                        postHoverClickScale ||
                        postHoverClickBackgroundColour ||
                        postHoverClickTextColour ||
                        postHoverClickBorderColour
                            ? {
                                  scale: postHoverClickScale !== 1 ? postHoverClickScale : undefined,
                                  colours: {
                                      background:
                                          postHoverClickBackgroundColour !== ""
                                              ? postHoverClickBackgroundColour
                                              : undefined,
                                      text: postHoverClickTextColour !== "" ? postHoverClickTextColour : undefined,
                                      border:
                                          postHoverClickBorderColour !== "" ? postHoverClickBorderColour : undefined,
                                  },
                              }
                            : undefined,
                },
            },
            contents: {
                preClickContent: {
                    title: preClickTitle !== "" ? preClickTitle : undefined,
                    icon: preClickIcon !== "" ? preClickIcon : undefined,
                    contentData: getHotspotContentDataFromNormalModeOrPresentationMode("preclick"),
                    presentationModeContentWidthHeight: prePresentationModeHotspotWidthHeight,
                },
                postClickContent: {
                    title: postClickTitle !== "" ? postClickTitle : undefined,
                    icon: postClickIcon !== "" ? postClickIcon : undefined,
                    contentData: getHotspotContentDataFromNormalModeOrPresentationMode("postclick"),
                    presentationModeContentWidthHeight: postPresentationModeHotspotWidthHeight,
                },
            },
            actions: {
                movement:
                    actionSceneId || actionSceneDelay
                        ? {
                              sceneId: actionSceneId !== "" ? actionSceneId : undefined,
                              delay: actionSceneDelay !== 0 ? actionSceneDelay : undefined,
                          }
                        : undefined,

                navigate: actionNavigate !== "" ? actionNavigate : undefined,
                appFeedback:
                    actionFeedbackImage ||
                    actionFeedbackVideo ||
                    actionFeedbackAudio ||
                    actionFeedbackInput ||
                    actionProceed
                        ? {
                              image: actionFeedbackImage !== "" ? actionFeedbackImage : undefined,
                              video: actionFeedbackVideo !== "" ? actionFeedbackVideo : undefined,
                              audio: actionFeedbackAudio !== "" ? actionFeedbackAudio : undefined,
                              input: actionFeedbackInput !== "" ? actionFeedbackInput : undefined,
                              proceed: actionProceed !== false ? actionProceed : undefined,
                              delay: actionProceed && actionFeedbackDelay !== 0 ? actionFeedbackDelay : undefined,
                          }
                        : undefined,

                closeable: actionCloseable ? actionCloseable : undefined,
                renderCondition: actionRenderConditions.length > 0 ? actionRenderConditions : undefined,
            },
        } as IHotspot;
    }

    function saveHotspot() {
        if (currentHotspot.currentHotspot) {
            const newHotspot = createNewHotspot();
            //--------------------------------------------------------------------------------------------------------
            // If Answer hotspot
            if (videoCurrentState === "Question") {
                // Creates the hotspot with the new data
                updateQuestionHotspot(newHotspot);
            }
            //--------------------------------------------------------------------------------------------------------
            // If scene hotspot
            else {
                updateHotspot(newHotspot);
            }

            if (actionFeedbackImage) {
                updateAllScenarioMedia({
                    id: scenarioId!,
                    media_type: EDraggableOptions.IMAGE,
                    media_ids: [actionFeedbackImage.split("images/")[1].split(".")[0]],
                });
            }
            if (actionFeedbackVideo) {
                updateAllScenarioMedia({
                    id: scenarioId!,
                    media_type: EDraggableOptions.VIDEO,
                    media_ids: [actionFeedbackVideo.split("video/")[1].split(".")[0]],
                });
            }
            if (actionFeedbackAudio) {
                updateAllScenarioMedia({
                    id: scenarioId!,
                    media_type: EDraggableOptions.AUDIO,
                    media_ids: [actionFeedbackAudio.split("audio/")[1].split(".")[0]],
                });
            }
        }
    }

    useEffect(() => {
        handleImageOnceUploaded();
    }, [mediaUploadLoading, uploadComplete]);

    async function handleImageOnceUploaded() {
        if (!mediaUploadLoading && uploadComplete) {
            const imgDimensions = await getImageDimensionsBeforeDrop(
                `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${mediaId}.png`,
                100,
            );

            dispatch(
                setCurrentRichTextEditorPresentationModeDataToDropObject({
                    mediaType: EContentDataType.IMAGE,
                    content: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${mediaId}.png`,
                    dropOffsetCoords: {
                        x: 160 - imgDimensions.width / 2,
                        y: 110 - imgDimensions.height / 2,
                    },
                    width: imgDimensions.width,
                    height: imgDimensions.height,
                }),
            );
        }
    }

    return (
        <VTDialog
            disableEnforceFocus={true}
            sx={sx}
            open={openDialog}
            handleClose={() => {
                dispatch(
                    setCurrentHotspot({
                        currentHotspot: undefined,
                        open: false,
                    }),
                );

                dispatch(setEditHotspotDialogIsCurrentlyOpen(false));
            }}
            size="xl"
            title="Hotspot editor"
            primaryArea={
                <>
                    {currentHotspot &&
                        currentHotspot.currentHotspot &&
                        !currentHotspot.currentHotspot.id.includes("_template") && (
                            <VTButton
                                onClick={() => {
                                    createQuestionHotspot({
                                        groupId: userId,
                                        hotspot: createNewHotspot(`${uuid()}_template`),
                                    });
                                    saveHotspot();
                                    dispatch(
                                        setCurrentHotspot({
                                            currentHotspot: undefined,
                                            open: false,
                                        }),
                                    );
                                }}
                            >
                                Save Template
                            </VTButton>
                        )}
                    <VTButton
                        color={"secondary"}
                        onClick={() =>
                            dispatch(
                                setCurrentHotspot({
                                    currentHotspot: undefined,
                                    open: false,
                                }),
                            )
                        }
                    >
                        Cancel
                    </VTButton>

                    {currentHotspot &&
                    currentHotspot.currentHotspot &&
                    currentHotspot.currentHotspot.id.includes("_template") ? (
                        <VTButton
                            onClick={() => {
                                updateQuestionHotspot(createNewHotspot());
                                dispatch(
                                    setCurrentHotspot({
                                        currentHotspot: undefined,
                                        open: false,
                                    }),
                                );
                            }}
                        >
                            Save Template
                        </VTButton>
                    ) : (
                        <VTButton
                            onClick={() => {
                                saveHotspot();
                                dispatch(
                                    setCurrentHotspot({
                                        currentHotspot: undefined,
                                        open: false,
                                    }),
                                );
                            }}
                        >
                            Save
                        </VTButton>
                    )}
                </>
            }
        >
            <Grid container justifyContent={"space-evenly"} spacing={2} sx={{ height: "100%" }}>
                {currentHotspot && currentHotspot.currentHotspot && (
                    <Grid
                        container
                        sx={{
                            position: "absolute",
                            zIndex: 10000,
                            background: "#393939",
                            right: 0,
                            top: "64px",
                            bottom: "51px",
                            width: hotspotPreview ? `${getHotspotPreviewPxWidth()}px` : 0,
                        }}
                        justifyContent={"center"}
                        alignContent={"center"}
                    >
                        <Tooltip title={"Preview hotspot"}>
                            <Grid
                                sx={{
                                    position: "absolute",
                                    zIndex: 10000,
                                    right: hotspotPreview ? `${getHotspotPreviewPxWidth()}px` : 0,
                                    top: "50px",
                                }}
                            >
                                <VTButton
                                    sx={{
                                        borderRadius: "15px 0 0 15px",
                                        background: "#393939",
                                        ":hover": {
                                            background: "#494949",
                                        },
                                    }}
                                    type={"icon"}
                                    onClick={() => setHotspotPreview(!hotspotPreview)}
                                >
                                    {hotspotPreview ? <VisibilityOffRounded /> : <VisibilityRounded />}
                                </VTButton>
                            </Grid>
                        </Tooltip>
                        {hotspotPreview && (
                            <div
                                style={{
                                    overflow: "hidden",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <VTHotspotPreview
                                    hotspotOpen={hotspotOpen}
                                    setHotspotOpen={setHotspotOpen}
                                    currentHotspotElement={
                                        {
                                            id: currentHotspot.currentHotspot!.id,
                                            location: currentHotspot?.currentHotspot!.location,
                                            type: currentHotspot?.currentHotspot!.type,
                                            styles: {
                                                preClickStyles: {
                                                    colours: {
                                                        background:
                                                            preClickBackgroundColour !== ""
                                                                ? preClickBackgroundColour
                                                                : undefined,
                                                        text:
                                                            preClickTextColour !== "" ? preClickTextColour : undefined,
                                                        border:
                                                            preClickBorderColour !== ""
                                                                ? preClickBorderColour
                                                                : undefined,
                                                    },
                                                    scale: preClickScale !== 1 ? preClickScale : undefined,
                                                    transform: transformCheckedPre ? transformCheckedPre : undefined,
                                                    hover:
                                                        preHoverClickScale ||
                                                        preHoverClickBackgroundColour ||
                                                        preHoverClickTextColour ||
                                                        preHoverClickBorderColour
                                                            ? {
                                                                  scale:
                                                                      preHoverClickScale !== 1
                                                                          ? preHoverClickScale
                                                                          : undefined,
                                                                  colours: {
                                                                      background:
                                                                          preHoverClickBackgroundColour !== ""
                                                                              ? preHoverClickBackgroundColour
                                                                              : undefined,
                                                                      text:
                                                                          preHoverClickTextColour !== ""
                                                                              ? preHoverClickTextColour
                                                                              : undefined,
                                                                      border:
                                                                          preHoverClickBorderColour !== ""
                                                                              ? preHoverClickBorderColour
                                                                              : undefined,
                                                                  },
                                                              }
                                                            : undefined,
                                                },
                                                postClickStyles: {
                                                    colours: {
                                                        background:
                                                            postClickBackgroundColour !== ""
                                                                ? postClickBackgroundColour
                                                                : undefined,
                                                        text:
                                                            postClickTextColour !== ""
                                                                ? postClickTextColour
                                                                : undefined,
                                                        border:
                                                            postClickBorderColour !== ""
                                                                ? postClickBorderColour
                                                                : undefined,
                                                    },
                                                    scale: undefined,
                                                    transform: transformCheckedPost ? transformCheckedPost : undefined,
                                                    hover:
                                                        postHoverClickScale ||
                                                        postHoverClickBackgroundColour ||
                                                        postHoverClickTextColour ||
                                                        postHoverClickBorderColour
                                                            ? {
                                                                  scale:
                                                                      postHoverClickScale !== 1
                                                                          ? postHoverClickScale
                                                                          : undefined,
                                                                  colours: {
                                                                      background:
                                                                          postHoverClickBackgroundColour !== ""
                                                                              ? postHoverClickBackgroundColour
                                                                              : undefined,
                                                                      text:
                                                                          postHoverClickTextColour !== ""
                                                                              ? postHoverClickTextColour
                                                                              : undefined,
                                                                      border:
                                                                          postHoverClickBorderColour !== ""
                                                                              ? postHoverClickBorderColour
                                                                              : undefined,
                                                                  },
                                                              }
                                                            : undefined,
                                                },
                                            },
                                            contents: {
                                                preClickContent: {
                                                    title: preClickTitle !== "" ? preClickTitle : undefined,
                                                    icon: preClickIcon !== "" ? preClickIcon : undefined,
                                                    contentData:
                                                        getHotspotContentDataFromNormalModeOrPresentationMode(
                                                            "preclick",
                                                        ),
                                                    presentationModeContentWidthHeight:
                                                        prePresentationModeHotspotWidthHeight,
                                                },
                                                postClickContent: {
                                                    title: postClickTitle !== "" ? postClickTitle : undefined,
                                                    icon: postClickIcon !== "" ? postClickIcon : undefined,
                                                    contentData:
                                                        getHotspotContentDataFromNormalModeOrPresentationMode(
                                                            "postclick",
                                                        ),
                                                    presentationModeContentWidthHeight:
                                                        postPresentationModeHotspotWidthHeight,
                                                },
                                            },
                                            actions: {
                                                movement:
                                                    actionSceneId || actionSceneDelay
                                                        ? {
                                                              sceneId: actionSceneId !== "" ? actionSceneId : undefined,
                                                              delay:
                                                                  actionSceneDelay !== 0 ? actionSceneDelay : undefined,
                                                          }
                                                        : undefined,

                                                navigate: actionNavigate !== "" ? actionNavigate : undefined,
                                                appFeedback:
                                                    actionFeedbackImage ||
                                                    actionFeedbackVideo ||
                                                    actionFeedbackAudio ||
                                                    actionFeedbackInput ||
                                                    actionProceed
                                                        ? {
                                                              image:
                                                                  actionFeedbackImage !== ""
                                                                      ? actionFeedbackImage
                                                                      : undefined,
                                                              video:
                                                                  actionFeedbackVideo !== ""
                                                                      ? actionFeedbackVideo
                                                                      : undefined,
                                                              audio:
                                                                  actionFeedbackAudio !== ""
                                                                      ? actionFeedbackAudio
                                                                      : undefined,
                                                              input:
                                                                  actionFeedbackInput !== ""
                                                                      ? actionFeedbackInput
                                                                      : undefined,
                                                              proceed:
                                                                  actionProceed !== false ? actionProceed : undefined,
                                                              delay:
                                                                  actionProceed && actionFeedbackDelay !== 0
                                                                      ? actionFeedbackDelay
                                                                      : undefined,
                                                          }
                                                        : undefined,

                                                closeable: actionCloseable ? actionCloseable : undefined,
                                            },
                                        } as IHotspot
                                    }
                                />
                            </div>
                        )}
                    </Grid>
                )}
                {/*-----------------------------------------------------------------------------------------------*/}
                {/* Button Switch */}
                {/*-----------------------------------------------------------------------------------------------*/}
                <Grid
                    item
                    container
                    xs={12}
                    sx={{
                        marginTop: "0px",
                        justifyContent: "center",
                        maxHeight: "40px",
                        paddingRight: matches && hotspotPreview ? `${getHotspotPreviewPxWidth()}px` : 0,
                    }}
                >
                    <VTButton
                        onClick={() => setCurrentEditTab("content")}
                        sx={{
                            borderBottom:
                                currentEditTab === "content" ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                            maxHeight: "40px",
                        }}
                        type="underline"
                    >
                        <VTTypography>Content</VTTypography>
                    </VTButton>

                    <VTButton
                        onClick={() => {
                            dispatch(
                                setCurrentSidePanel({ currentTab: undefined, currentWidth: 0, option: undefined }),
                            );
                            setCurrentEditTab("style");
                        }}
                        sx={{
                            borderBottom:
                                currentEditTab === "style" ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                            maxHeight: "40px",
                        }}
                        type="underline"
                    >
                        <VTTypography>Styles</VTTypography>
                    </VTButton>

                    <VTButton
                        onClick={() => setCurrentEditTab("actions")}
                        sx={{
                            borderBottom:
                                currentEditTab === "actions" ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                            maxHeight: "40px",
                        }}
                        type="underline"
                    >
                        <VTTypography>Actions</VTTypography>
                    </VTButton>

                    <VTButton
                        onClick={() => {
                            setCurrentEditTab("render");
                            dispatch(
                                setCurrentSidePanel({ currentTab: undefined, currentWidth: 0, option: undefined }),
                            );
                        }}
                        sx={{
                            borderBottom:
                                currentEditTab === "render" ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                            maxHeight: "40px",
                        }}
                        type="underline"
                    >
                        <VTTypography>Display</VTTypography>
                    </VTButton>
                </Grid>

                {/*-----------------------------------------------------------------------------------------------*/}
                {/* Pre / post click switch */}
                {/*-----------------------------------------------------------------------------------------------*/}
                {(currentEditTab === "content" || currentEditTab === "style") && (
                    <Grid item container xs={2} justifyContent={"center"} alignContent={"center"}>
                        <VTButton
                            sx={{
                                height: "50%",
                                minHeight: "200px",
                                marginRight: "10px",
                                borderRadius: "15px 15px 0px 0px",
                                backgroundColor:
                                    currentPrePostTab === "pre"
                                        ? theme.palette.primary.main
                                        : theme.palette.background.default,
                                ":hover": {
                                    backgroundColor:
                                        currentPrePostTab === "pre"
                                            ? theme.palette.primary.light
                                            : theme.palette.background.paper,
                                },
                            }}
                            onClick={() => {
                                setCurrentPrePostTab("pre");
                                setHotspotOpen(false);
                            }}
                        >
                            <Grid container justifyContent={"center"} alignContent={"center"}>
                                <Grid item sx={{ color: theme.palette.text.primary }}>
                                    Default
                                </Grid>
                            </Grid>
                        </VTButton>

                        <VTButton
                            sx={{
                                height: "50%",
                                minHeight: "200px",
                                borderRadius: "0px 0px 15px 15px ",
                                marginRight: "10px",
                                backgroundColor:
                                    currentPrePostTab === "post"
                                        ? theme.palette.primary.main
                                        : theme.palette.background.default,
                                ":hover": {
                                    backgroundColor:
                                        currentPrePostTab === "post"
                                            ? theme.palette.primary.light
                                            : theme.palette.background.paper,
                                },
                            }}
                            onClick={() => {
                                setCurrentPrePostTab("post");
                                setHotspotOpen(true);
                            }}
                        >
                            <Grid container justifyContent={"center"} alignContent={"center"}>
                                <Grid item sx={{ color: theme.palette.text.primary }}>
                                    Clicked
                                </Grid>
                            </Grid>
                        </VTButton>
                    </Grid>
                )}

                <Grid
                    item
                    container
                    xs={currentEditTab === "content" || currentEditTab === "style" ? 10 : 12}
                    justifyContent={"space-evenly"}
                    alignContent={"center"}
                    spacing={1}
                    sx={matches && hotspotPreview ? { paddingRight: `${getHotspotPreviewPxWidth()}px` } : {}}
                >
                    {/*-----------------------------------------------------------------------------------------------*/}
                    {/* Content  */}
                    {/*-----------------------------------------------------------------------------------------------*/}
                    {currentEditTab === "content" && (
                        <>
                            {currentPrePostTab === "pre" ? (
                                <>
                                    {/* --------------------------------------------------------------- */}
                                    {/* Title */}
                                    <Grid item container xs={9}>
                                        <Grid item>
                                            <VTTypography>Title</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <VTTextField
                                                onFocus={() => {
                                                    richTextEditorPresentationModeRef.current?.deselect();
                                                }}
                                                value={preClickTitle}
                                                onChange={(e) => setPreClickTitle(e)}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* --------------------------------------------------------------- */}
                                    {/* Icons */}
                                    <Grid item container xs={3}>
                                        <Grid item>
                                            <VTTypography>Icon</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <VTIconDropDown
                                                currentIcon={preClickIcon as IconName}
                                                setCurrentIcon={setPreClickIcon}
                                                sx={{ marginLeft: "10px" }}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* --------------------------------------------------------------- */}
                                    {/* Title */}

                                    <Grid item container xs={12} sx={{}}>
                                        <Grid sx={{ display: "flex", alignItems: "center" }} item>
                                            <VTTypography>Content</VTTypography>
                                            <Typography sx={{ marginLeft: "5px", marginTop: "2px" }} variant="caption">
                                                {preEditorMode === "normal mode"
                                                    ? "rich text editor normal mode"
                                                    : "presentation mode"}
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    preEditorMode === "normal mode"
                                                        ? "hit the toggle to switch to presentation mode hotspot editing"
                                                        : "hit the toggle to switch to normal mode hotspot editing"
                                                }
                                            >
                                                <InfoRounded
                                                    sx={{
                                                        fontSize: "1em",
                                                        marginLeft: "3px",
                                                        marginBottom: "5px",
                                                    }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {preEditorMode === "normal mode" ? (
                                                typeof preRichTextEditorInitialContent === "string" && (
                                                    <VTRichTextEditor
                                                        // @ts-ignore
                                                        onRichTextEditorIdRefReady={(id: string) => {
                                                            dispatch(setCurrentRichTextEditorNormalModeId(id));
                                                        }}
                                                        // currentlyDragging={currentlyDraggingObj}
                                                        dropTextAndSvgImage={richTextEditorDropMsgAndImage}
                                                        // onDropImagePxWidthForRatio={50}
                                                        onToggleChanged={() => {
                                                            setPreEditorMode("presentation mode");
                                                            // setPreRichTextEditorInitialContent("");
                                                        }}
                                                        onClickImageIcon={() => {
                                                            dispatch(
                                                                setCurrentSidePanel({
                                                                    currentTab: "Images",
                                                                    currentWidth: currentSidePannelDesiredWidth,
                                                                    option: undefined,
                                                                }),
                                                            );
                                                        }}
                                                        onClickVideoIcon={() => {
                                                            dispatch(
                                                                setCurrentSidePanel({
                                                                    currentTab: "Videos",
                                                                    currentWidth: currentSidePannelDesiredWidth,
                                                                    option: undefined,
                                                                }),
                                                            );
                                                        }}
                                                        onClickAudioIcon={() => {
                                                            dispatch(
                                                                setCurrentSidePanel({
                                                                    currentTab: "Audio",
                                                                    currentWidth: currentSidePannelDesiredWidth,
                                                                    option: undefined,
                                                                }),
                                                            );
                                                        }}
                                                        startingHtmlContent={preRichTextEditorInitialContent}
                                                        onChange={(htmlStringContent: string) => {
                                                            // if (currentPrePostTab === "post") {
                                                            setPreClickContent(htmlStringContent);
                                                            // } else {
                                                            //   setPreClickContent(htmlStringContent);
                                                            // }
                                                        }}
                                                        sx={{ height: "350px" }}
                                                    />
                                                )
                                            ) : (
                                                // rte presentation mode
                                                <VTRichTextEditorPresentationMode
                                                    ref={richTextEditorPresentationModeRef}
                                                    sx={{
                                                        minWidth: "535px",
                                                        maxWidth: "535px",
                                                    }}
                                                    width={prePresentationModeHotspotWidthHeight.w} // rename this prop to cropBoxWidth
                                                    height={prePresentationModeHotspotWidthHeight.h} // rename this prop to cropBoxHeight
                                                    initialHotspotPresentationData={
                                                        Array.isArray(preRichTextEditorInitialContent)
                                                            ? preRichTextEditorInitialContent
                                                            : undefined
                                                    }
                                                    currentlyDragging={
                                                        currentRichTextEditorPresentationModeDataToDropObject
                                                    }
                                                    onDataHasFinishedDrop={() => {
                                                        dispatch(
                                                            setCurrentRichTextEditorPresentationModeDataToDropObject(
                                                                undefined,
                                                            ),
                                                        );
                                                    }}
                                                    onHotspotPresentationContentDataChanged={(
                                                        updatedData: IContentData[],
                                                    ) => {
                                                        // console.log(
                                                        //     "on change pre rte presentation mode triggered",
                                                        // );
                                                        setPreClickContent(updatedData);
                                                    }}
                                                    onDataContainerDimensionsChanged={(width, height) => {
                                                        setPrePresentationModeHotspotWidthHeight({
                                                            w: width,
                                                            h: height,
                                                        });
                                                    }}
                                                    onGetDragOverXYOffset={(x, y) => {
                                                        dispatch(
                                                            setCurrentRichTextEditorPresentationModeDropOffsetsCoords({
                                                                x,
                                                                y,
                                                            }),
                                                        );
                                                    }}
                                                    onToggleClicked={() => {
                                                        setPreEditorMode("normal mode");
                                                        // setPreRichTextEditorInitialContent("");
                                                        if (currentSidePanel.currentTab === "Text") {
                                                            dispatch(
                                                                setCurrentSidePanel({
                                                                    currentTab: undefined,
                                                                    currentWidth: 0,
                                                                    option: undefined,
                                                                }),
                                                            );
                                                        }
                                                    }}
                                                    onClickTextIcon={() => {
                                                        dispatch(
                                                            setCurrentSidePanel({
                                                                currentTab: "Text",
                                                                currentWidth: currentSidePannelDesiredWidth,
                                                                option: undefined,
                                                            }),
                                                        );
                                                    }}
                                                    onClickImageIcon={() => {
                                                        dispatch(
                                                            setCurrentSidePanel({
                                                                currentTab: "Images",
                                                                currentWidth: currentSidePannelDesiredWidth,
                                                                option: undefined,
                                                            }),
                                                        );
                                                    }}
                                                    onClickVideoIcon={() => {
                                                        dispatch(
                                                            setCurrentSidePanel({
                                                                currentTab: "Videos",
                                                                currentWidth: currentSidePannelDesiredWidth,
                                                                option: undefined,
                                                            }),
                                                        );
                                                    }}
                                                    onClickAudioIcon={() => {
                                                        dispatch(
                                                            setCurrentSidePanel({
                                                                currentTab: "Audio",
                                                                currentWidth: currentSidePannelDesiredWidth,
                                                                option: undefined,
                                                            }),
                                                        );
                                                    }}
                                                    lockResizeObserver={false}
                                                    contentLoading={mediaUploadLoading}
                                                    onImagePaste={({ url, type }) => {
                                                        handleUpload(uuid(), url, type);
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {/* --------------------------------------------------------------- */}
                                    {/* Title */}

                                    <Grid item container xs={9}>
                                        <Grid item>
                                            <VTTypography>Title</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <VTTextField
                                                onFocus={() => {
                                                    richTextEditorPresentationModeRef.current?.deselect();
                                                }}
                                                value={postClickTitle}
                                                onChange={(e) => setPostClickTitle(e)}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* --------------------------------------------------------------- */}
                                    {/* Icons */}

                                    <Grid item container xs={3}>
                                        <Grid item>
                                            <VTTypography>Icon</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <VTIconDropDown
                                                currentIcon={postClickIcon as IconName}
                                                setCurrentIcon={setPostClickIcon}
                                                sx={{ marginLeft: "10px" }}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* --------------------------------------------------------------- */}
                                    {/* Title */}

                                    <Grid item container xs={12}>
                                        <Grid sx={{ display: "flex", alignItems: "center" }} item>
                                            <VTTypography>Content</VTTypography>
                                            <Typography sx={{ marginLeft: "5px", marginTop: "2px" }} variant="caption">
                                                {postEditorMode === "normal mode"
                                                    ? "rich text editor normal mode"
                                                    : "presentation mode"}
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    postEditorMode === "normal mode"
                                                        ? "hit the toggle to switch to presentation mode hotspot editing"
                                                        : "hit the toggle to switch to normal mode hotspot editing"
                                                }
                                            >
                                                <InfoRounded
                                                    sx={{
                                                        fontSize: "1em",
                                                        marginLeft: "3px",
                                                        marginBottom: "5px",
                                                    }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <VTTextField
                        value={postClickContent}
                        onChange={(e) => setPostClickContent(e)}
                      /> */}
                                            {postEditorMode === "normal mode" ? (
                                                typeof postRichTextEditorInitialContent === "string" && (
                                                    <VTRichTextEditor
                                                        // @ts-ignore
                                                        // currentlyDragging={currentlyDraggingObj}
                                                        onRichTextEditorIdRefReady={(id: string) => {
                                                            dispatch(setCurrentRichTextEditorNormalModeId(id));
                                                        }}
                                                        // currentlyDragging={currentlyDraggingObj}
                                                        dropTextAndSvgImage={richTextEditorDropMsgAndImage}
                                                        onDropImagePxWidthForRatio={50}
                                                        onClickImageIcon={() => {
                                                            dispatch(
                                                                setCurrentSidePanel({
                                                                    currentTab: "Images",
                                                                    currentWidth: currentSidePannelDesiredWidth,
                                                                    option: undefined,
                                                                }),
                                                            );
                                                        }}
                                                        onClickVideoIcon={() => {
                                                            dispatch(
                                                                setCurrentSidePanel({
                                                                    currentTab: "Videos",
                                                                    currentWidth: currentSidePannelDesiredWidth,
                                                                    option: undefined,
                                                                }),
                                                            );
                                                        }}
                                                        onClickAudioIcon={() => {
                                                            dispatch(
                                                                setCurrentSidePanel({
                                                                    currentTab: "Audio",
                                                                    currentWidth: currentSidePannelDesiredWidth,
                                                                    option: undefined,
                                                                }),
                                                            );
                                                        }}
                                                        startingHtmlContent={postRichTextEditorInitialContent}
                                                        onChange={(htmlStringContent: string) => {
                                                            // if (currentPrePostTab === "post") {
                                                            setPostClickContent(htmlStringContent);
                                                            // } else {
                                                            //   setPreClickContent(htmlStringContent);
                                                            // }
                                                        }}
                                                        onToggleChanged={() => {
                                                            setPostEditorMode("presentation mode");
                                                            // setPostRichTextEditorInitialContent("");
                                                        }}
                                                        sx={{ height: "350px" }}
                                                    />
                                                )
                                            ) : (
                                                // rte presentation mode
                                                <VTRichTextEditorPresentationMode
                                                    ref={richTextEditorPresentationModeRef}
                                                    sx={{
                                                        minWidth: "535px",
                                                        maxWidth: "535px",
                                                    }}
                                                    width={postPresentationModeHotspotWidthHeight.w} // rename this prop to cropBoxWidth
                                                    height={postPresentationModeHotspotWidthHeight.h} // rename this prop to cropBoxHeight
                                                    initialHotspotPresentationData={
                                                        Array.isArray(postRichTextEditorInitialContent)
                                                            ? postRichTextEditorInitialContent
                                                            : undefined
                                                    }
                                                    currentlyDragging={
                                                        currentRichTextEditorPresentationModeDataToDropObject
                                                    }
                                                    onDataHasFinishedDrop={() => {
                                                        dispatch(
                                                            setCurrentRichTextEditorPresentationModeDataToDropObject(
                                                                undefined,
                                                            ),
                                                        );
                                                    }}
                                                    onHotspotPresentationContentDataChanged={(
                                                        updatedData: IContentData[],
                                                    ) => {
                                                        // console.log("updated rte pres mode data", updatedData);
                                                        setPostClickContent(updatedData);
                                                    }}
                                                    onDataContainerDimensionsChanged={(width, height) => {
                                                        setPostPresentationModeHotspotWidthHeight({
                                                            w: width,
                                                            h: height,
                                                        });
                                                    }}
                                                    onGetDragOverXYOffset={(x, y) => {
                                                        dispatch(
                                                            setCurrentRichTextEditorPresentationModeDropOffsetsCoords({
                                                                x,
                                                                y,
                                                            }),
                                                        );
                                                    }}
                                                    onToggleClicked={() => {
                                                        setPostEditorMode("normal mode");
                                                        // setPostRichTextEditorInitialContent("");
                                                        if (currentSidePanel.currentTab === "Text") {
                                                            dispatch(
                                                                setCurrentSidePanel({
                                                                    currentTab: undefined,
                                                                    currentWidth: 0,
                                                                    option: undefined,
                                                                }),
                                                            );
                                                        }
                                                    }}
                                                    onClickTextIcon={() => {
                                                        dispatch(
                                                            setCurrentSidePanel({
                                                                currentTab: "Text",
                                                                currentWidth: currentSidePannelDesiredWidth,
                                                                option: undefined,
                                                            }),
                                                        );
                                                    }}
                                                    onClickImageIcon={() => {
                                                        dispatch(
                                                            setCurrentSidePanel({
                                                                currentTab: "Images",
                                                                currentWidth: currentSidePannelDesiredWidth,
                                                                option: undefined,
                                                            }),
                                                        );
                                                    }}
                                                    onClickVideoIcon={() => {
                                                        dispatch(
                                                            setCurrentSidePanel({
                                                                currentTab: "Videos",
                                                                currentWidth: currentSidePannelDesiredWidth,
                                                                option: undefined,
                                                            }),
                                                        );
                                                    }}
                                                    onClickAudioIcon={() => {
                                                        dispatch(
                                                            setCurrentSidePanel({
                                                                currentTab: "Audio",
                                                                currentWidth: currentSidePannelDesiredWidth,
                                                                option: undefined,
                                                            }),
                                                        );
                                                    }}
                                                    lockResizeObserver={false}
                                                    contentLoading={mediaUploadLoading}
                                                    onImagePaste={({ url, type }) => {
                                                        handleUpload(uuid(), url, type);
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}

                    {/*-----------------------------------------------------------------------------------------------*/}
                    {/* Style  */}
                    {/*-----------------------------------------------------------------------------------------------*/}
                    {currentEditTab === "style" && (
                        <Grid
                            container
                            sx={{ height: "100%" }}
                            item
                            justifyContent={"space-evenly"}
                            alignContent={"space-evenly"}
                            spacing={1}
                        >
                            {currentPrePostTab === "pre" ? (
                                <>
                                    <Grid container item xs={12} justifyContent={"center"}>
                                        <Paper sx={{ width: "100%", backgroundColor: "#a9a9a9", padding: "2px" }}>
                                            <Grid container justifyContent={"center"} spacing={2}>
                                                <Tooltip
                                                    placement={"bottom"}
                                                    title={
                                                        <Grid item xs={12}>
                                                            <VTTypography align="center" type="Heading4">
                                                                Background Color
                                                            </VTTypography>
                                                            <SketchPicker
                                                                color={preClickBackgroundColour}
                                                                onChange={(e: any) =>
                                                                    setPreClickBackgroundColour(e.rgb)
                                                                }
                                                            />
                                                        </Grid>
                                                    }
                                                >
                                                    <Grid item>
                                                        <VTButton>
                                                            <GridOnIcon />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip
                                                    placement={"bottom"}
                                                    title={
                                                        <Grid item xs={12}>
                                                            <VTTypography align="center" type="Heading4">
                                                                Text Color
                                                            </VTTypography>
                                                            <Grid item xs={12}>
                                                                <SketchPicker
                                                                    color={preClickTextColour}
                                                                    onChange={(e: any) => setPreClickTextColour(e.rgb)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                >
                                                    <Grid item>
                                                        <VTButton>
                                                            <FormatColorTextIcon />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip
                                                    placement={"bottom"}
                                                    title={
                                                        <Grid item xs={12}>
                                                            <VTTypography align="center" type="Heading4">
                                                                Border Color
                                                            </VTTypography>
                                                            <SketchPicker
                                                                color={preClickBorderColour}
                                                                onChange={(e: any) => setPreClickBorderColour(e.rgb)}
                                                            />
                                                        </Grid>
                                                    }
                                                >
                                                    <Grid item>
                                                        <VTButton>
                                                            <BorderStyleIcon />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip title={"Transfer colours to hovered"}>
                                                    <Grid item>
                                                        <VTButton
                                                            type="icon"
                                                            onClick={() => {
                                                                setPreHoverClickBackgroundColour(
                                                                    preClickBackgroundColour,
                                                                );
                                                                setPreHoverClickBorderColour(preClickBorderColour);
                                                                setPreHoverClickTextColour(preClickTextColour);
                                                            }}
                                                            sx={{ height: "35px", width: "35px", marginLeft: "20px" }}
                                                        >
                                                            <ArrowRightAltRoundedIcon />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>

                                                {/* #TODO; reenable hover styles */}
                                                <Tooltip
                                                    placement={"bottom"}
                                                    sx={{ marginLeft: "20px" }}
                                                    title={
                                                        <Grid item xs={12}>
                                                            <VTTypography align="center" type="Heading4">
                                                                Hover Background Color
                                                            </VTTypography>
                                                            <SketchPicker
                                                                color={preHoverClickBackgroundColour}
                                                                onChange={(e: any) =>
                                                                    setPreHoverClickBackgroundColour(e.rgb)
                                                                }
                                                            />
                                                        </Grid>
                                                    }
                                                >
                                                    <Grid item>
                                                        <VTButton>
                                                            <GridOnIcon sx={{ color: "#7AFFFF" }} />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip
                                                    placement={"bottom"}
                                                    title={
                                                        <Grid item xs={12}>
                                                            <VTTypography align="center" type="Heading4">
                                                                Hover Text Color
                                                            </VTTypography>
                                                            <Grid item xs={12}>
                                                                <SketchPicker
                                                                    color={preHoverClickTextColour}
                                                                    onChange={(e: any) =>
                                                                        setPreHoverClickTextColour(e.rgb)
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                >
                                                    <Grid item>
                                                        <VTButton>
                                                            <FormatColorTextIcon sx={{ color: "#7AFFFF" }} />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip
                                                    placement={"bottom"}
                                                    title={
                                                        <Grid item xs={12}>
                                                            <VTTypography align="center" type="Heading4">
                                                                Hover Border Color
                                                            </VTTypography>
                                                            <SketchPicker
                                                                color={preHoverClickBorderColour}
                                                                onChange={(e: any) =>
                                                                    setPreHoverClickBorderColour(e.rgb)
                                                                }
                                                            />
                                                        </Grid>
                                                    }
                                                >
                                                    <Grid item>
                                                        <VTButton>
                                                            <BorderStyleIcon sx={{ color: "#7AFFFF" }} />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    {/* --------------------------------------------------------------- */}
                                    {/* Title */}

                                    <Grid item container xs={10}>
                                        <Grid item>
                                            <VTTypography>Scale</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Slider
                                                defaultValue={1}
                                                getAriaValueText={() => preClickScale.toString()}
                                                step={0.1}
                                                valueLabelDisplay="auto"
                                                max={2}
                                                min={0.5}
                                                value={preClickScale}
                                                onChange={(e) => {
                                                    setPreClickScale(
                                                        // @ts-ignore
                                                        e.target.value,
                                                    );
                                                    // @ts-ignore
                                                    if (e.target.value > preHoverClickScale) {
                                                        // @ts-ignore
                                                        setPreHoverClickScale(e.target.value);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={10}>
                                        <Grid item>
                                            <VTTypography>Hover Scale</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Slider
                                                defaultValue={0.5}
                                                getAriaValueText={() => preHoverClickScale.toString()}
                                                step={0.1}
                                                valueLabelDisplay="auto"
                                                max={2.0}
                                                min={0.5}
                                                value={preHoverClickScale}
                                                onChange={(e) => {
                                                    // @ts-ignore
                                                    if (e.target.value >= preClickScale)
                                                        setPreHoverClickScale(
                                                            // @ts-ignore
                                                            e.target.value,
                                                        );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={10}>
                                        <Grid item xs={1}>
                                            <VTTypography>Fix Hotspot</VTTypography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Switch
                                                checked={transformCheckedPre}
                                                onChange={(e) => setTransformCheckedPre(e.target.checked)}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                        </Grid>
                                        <Tooltip title={"Transfer style settings to clicked"} placement="left">
                                            <Grid item container xs={2}>
                                                <VTButton
                                                    type="icon"
                                                    onClick={() => {
                                                        setPostClickBackgroundColour(preClickBackgroundColour);
                                                        setPostClickBorderColour(preClickBorderColour);
                                                        setPostClickTextColour(preClickTextColour);
                                                        setPostClickScale(preClickScale);
                                                        setPostHoverClickBackgroundColour(
                                                            preHoverClickBackgroundColour,
                                                        );
                                                        setPostHoverClickBorderColour(preHoverClickBorderColour);
                                                        setPostHoverClickTextColour(preHoverClickTextColour);
                                                        setPostHoverClickScale(preHoverClickScale);
                                                    }}
                                                >
                                                    <SwapVertRoundedIcon />
                                                </VTButton>
                                            </Grid>
                                        </Tooltip>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {/* --------------------------------------------------------------- */}
                                    {/* Title */}

                                    <Grid container item xs={12} justifyContent={"center"}>
                                        <Paper sx={{ width: "100%", backgroundColor: "#a9a9a9", padding: "2px" }}>
                                            <Grid container justifyContent={"center"} spacing={2}>
                                                <Tooltip
                                                    placement={"bottom"}
                                                    title={
                                                        <Grid item xs={12}>
                                                            <VTTypography align="center" type="Heading4">
                                                                Background Color
                                                            </VTTypography>
                                                            <SketchPicker
                                                                color={postClickBackgroundColour}
                                                                onChange={(e: any) =>
                                                                    setPostClickBackgroundColour(e.rgb)
                                                                }
                                                            />
                                                        </Grid>
                                                    }
                                                >
                                                    <Grid item>
                                                        <VTButton>
                                                            <GridOnIcon />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip
                                                    placement={"bottom"}
                                                    title={
                                                        <Grid item xs={12}>
                                                            <VTTypography align="center" type="Heading4">
                                                                Text Color
                                                            </VTTypography>
                                                            <Grid item xs={12}>
                                                                <SketchPicker
                                                                    color={postClickTextColour}
                                                                    onChange={(e: any) => setPostClickTextColour(e.rgb)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                >
                                                    <Grid item>
                                                        <VTButton>
                                                            <FormatColorTextIcon />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip
                                                    placement={"bottom"}
                                                    title={
                                                        <Grid item xs={12}>
                                                            <VTTypography align="center" type="Heading4">
                                                                Border Color
                                                            </VTTypography>
                                                            <SketchPicker
                                                                color={postClickBorderColour}
                                                                onChange={(e: any) => setPostClickBorderColour(e.rgb)}
                                                            />
                                                        </Grid>
                                                    }
                                                >
                                                    <Grid item>
                                                        <VTButton>
                                                            <BorderStyleIcon />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>

                                                <Tooltip title={"Transfer colours to hovered"}>
                                                    <Grid item>
                                                        <VTButton
                                                            type="icon"
                                                            onClick={() => {
                                                                setPostHoverClickBackgroundColour(
                                                                    postClickBackgroundColour,
                                                                );
                                                                setPostHoverClickBorderColour(postClickBorderColour);
                                                                setPostHoverClickTextColour(postClickTextColour);
                                                            }}
                                                            sx={{ height: "35px", width: "35px", marginLeft: "20px" }}
                                                        >
                                                            <ArrowRightAltRoundedIcon />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>
                                                {/* #TODO; reenable hover styles */}
                                                <Tooltip
                                                    placement={"bottom"}
                                                    sx={{ marginLeft: "20px" }}
                                                    title={
                                                        <Grid item xs={12}>
                                                            <VTTypography align="center" type="Heading4">
                                                                Hover Background Color
                                                            </VTTypography>
                                                            <SketchPicker
                                                                color={postHoverClickBackgroundColour}
                                                                onChange={(e: any) =>
                                                                    setPostHoverClickBackgroundColour(e.rgb)
                                                                }
                                                            />
                                                        </Grid>
                                                    }
                                                >
                                                    <Grid item>
                                                        <VTButton>
                                                            <GridOnIcon sx={{ color: "#7AFFFF" }} />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip
                                                    placement={"bottom"}
                                                    title={
                                                        <Grid item xs={12}>
                                                            <VTTypography align="center" type="Heading4">
                                                                Hover Text Color
                                                            </VTTypography>
                                                            <Grid item xs={12}>
                                                                <SketchPicker
                                                                    color={postHoverClickTextColour}
                                                                    onChange={(e: any) =>
                                                                        setPostHoverClickTextColour(e.rgb)
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                >
                                                    <Grid item>
                                                        <VTButton>
                                                            <FormatColorTextIcon sx={{ color: "#7AFFFF" }} />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip
                                                    placement={"bottom"}
                                                    title={
                                                        <Grid item xs={12}>
                                                            <VTTypography align="center" type="Heading4">
                                                                Hover Border Color
                                                            </VTTypography>
                                                            <SketchPicker
                                                                color={postHoverClickBorderColour}
                                                                onChange={(e: any) =>
                                                                    setPostHoverClickBorderColour(e.rgb)
                                                                }
                                                            />
                                                        </Grid>
                                                    }
                                                >
                                                    <Grid item>
                                                        <VTButton>
                                                            <BorderStyleIcon sx={{ color: "#7AFFFF" }} />
                                                        </VTButton>
                                                    </Grid>
                                                </Tooltip>
                                            </Grid>
                                        </Paper>
                                    </Grid>

                                    <Grid item container xs={10}>
                                        <Grid item>
                                            <VTTypography>Scale</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Slider
                                                defaultValue={1}
                                                getAriaValueText={() => postClickScale.toString()}
                                                step={0.1}
                                                valueLabelDisplay="auto"
                                                max={2}
                                                min={0.5}
                                                value={postClickScale}
                                                onChange={(e) => {
                                                    setPostClickScale(
                                                        // @ts-ignore
                                                        e.target.value,
                                                    );
                                                    // @ts-ignore
                                                    if (e.target.value > postHoverClickScale) {
                                                        // @ts-ignore
                                                        setPostHoverClickScale(e.target.value);
                                                    }
                                                }}
                                                // setPostClickScale(
                                                //     // @ts-ignore
                                                //     e.target.value,
                                                // )
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container xs={10}>
                                        <Grid item>
                                            <VTTypography>Hover Scale</VTTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Slider
                                                defaultValue={1}
                                                getAriaValueText={() => postHoverClickScale.toString()}
                                                step={0.1}
                                                valueLabelDisplay="auto"
                                                max={2}
                                                min={0.5}
                                                value={postHoverClickScale}
                                                onChange={(e) => {
                                                    // setPostHoverClickScale(
                                                    //     // @ts-ignore
                                                    //     e.target.value,
                                                    // );
                                                    // @ts-ignore
                                                    if (e.target.value >= postClickScale) {
                                                        // @ts-ignore
                                                        setPostHoverClickScale(e.target.value);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={10}>
                                        <Grid item xs={1}>
                                            <VTTypography>Fix Hotspot</VTTypography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Switch
                                                checked={transformCheckedPost}
                                                onChange={(e) => setTransformCheckedPost(e.target.checked)}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )}

                    {/*-----------------------------------------------------------------------------------------------*/}
                    {/* Actions  */}
                    {/*-----------------------------------------------------------------------------------------------*/}
                    {currentEditTab === "actions" && (
                        <>
                            {/* --------------------------------------------------------------- */}
                            {/* Title */}
                            <Grid item container xs={10} justifyContent={"space-between"}>
                                {/*-------------------------------------------------------------------------------*/}
                                {/* Move scene */}

                                <Grid item xs={9} container>
                                    <VTTypography>Move to scene</VTTypography>

                                    {currentHotspot &&
                                        currentHotspot.currentHotspot &&
                                        currentHotspot.currentHotspot.id.includes("_template") && (
                                            <Tooltip
                                                title={
                                                    "this feature might require adjustment when importing into other scenarios"
                                                }
                                            >
                                                <InfoRounded
                                                    sx={{ fontSize: "0.8em", marginLeft: "5px", cursor: "pointer" }}
                                                />
                                            </Tooltip>
                                        )}
                                </Grid>
                                {actionSceneId ? (
                                    <Grid item xs={3}>
                                        <VTTypography>Delay move to scene</VTTypography>
                                    </Grid>
                                ) : (
                                    <Grid item xs={3}>
                                        <VTTypography>Closeable Content</VTTypography>
                                    </Grid>
                                )}

                                <Grid item xs={8}>
                                    <Select
                                        value={actionSceneId}
                                        onChange={(e) => setActionSceneId(e.target.value)}
                                        sx={{ width: "100%" }}
                                        disabled={actionNavigate === "/"}
                                    >
                                        {scenes &&
                                            scenes
                                                .filter((sceneElement: IScene) => sceneElement.id !== sceneId)
                                                .map((scene: IScene) => (
                                                    <MenuItem value={scene.id}>{scene.name}</MenuItem>
                                                ))}
                                        <MenuItem value={""}>{"No scene"}</MenuItem>
                                    </Select>
                                </Grid>
                                {actionSceneId ? (
                                    <Grid item xs={3}>
                                        <TextField
                                            type={"number"}
                                            value={actionSceneDelay}
                                            onChange={(e) => {
                                                if (!(parseInt(e.target.value) < 0)) {
                                                    setActionSceneDelay(parseInt(e.target.value));
                                                }
                                            }}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs={3}>
                                        <VTButton
                                            type={actionCloseable ? "primary" : "underline"}
                                            onClick={() => setActionCloseable(!actionCloseable)}
                                            endIcon={actionCloseable ? <DoneRoundedIcon /> : <CloseRoundedIcon />}
                                            sx={{ width: "100%", height: "100%" }}
                                        >
                                            Closeable
                                        </VTButton>
                                    </Grid>
                                )}
                            </Grid>

                            {/* --------------------------------------------------------------- */}
                            {/* Feedback audio */}
                            <Grid item container xs={10}>
                                <Grid item>
                                    <VTTypography>Navigate to Url</VTTypography>
                                </Grid>
                                <Grid item xs={12}>
                                    <VTTextField value={actionNavigate} onChange={(e) => setActionNavigate(e)} />
                                </Grid>
                            </Grid>

                            {/* --------------------------------------------------------------- */}
                            {/* Feedback image */}
                            <Grid item container xs={10}>
                                <Grid item>
                                    <VTTypography>Feedback Image</VTTypography>
                                </Grid>

                                <VTImageDropper
                                    currentImage={actionFeedbackImage}
                                    setCurrentImage={setActionFeedbackImage}
                                    disabled={currentSidePanel.currentTab !== "Images"}
                                    droppableId={EDroppableContainerId.HOTSPOT_FEEDBACK_IMAGE_DROP}
                                />
                                <Grid
                                    xs={12}
                                    container
                                    item
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    marginBottom={"5px"}
                                >
                                    <Typography>Drop and drag an image from the image sidebar</Typography>
                                </Grid>
                            </Grid>

                            {/* --------------------------------------------------------------- */}
                            {/* Feedback video */}
                            <Grid item container xs={10}>
                                <Grid item>
                                    <VTTypography>Feedback Video</VTTypography>
                                </Grid>
                                {/*-------------------------------------------------------------------------------*/}
                                {/* Preview image */}
                                <VTVideoDropper
                                    currentVideo={actionFeedbackVideo}
                                    setCurrentVideo={setActionFeedbackVideo}
                                    disabled={currentSidePanel.currentTab !== "Videos"}
                                    droppableId={EDroppableContainerId.HOTSPOT_FEEDBACK_VIDEO_DROP}
                                />
                                <Grid
                                    xs={12}
                                    container
                                    item
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    marginBottom={"5px"}
                                >
                                    <Typography>Drop and drag an video from the video sidebar</Typography>
                                </Grid>
                            </Grid>

                            {/* --------------------------------------------------------------- */}
                            {/* Feedback audio */}
                            <Grid item container xs={10}>
                                <Grid item>
                                    <VTTypography>Feedback Audio</VTTypography>
                                </Grid>
                                {/*-------------------------------------------------------------------------------*/}
                                {/* Preview image */}
                                <VTAudioDropper
                                    currentAudio={actionFeedbackAudio}
                                    setCurrentAudio={handleAudioDropped}
                                    disabled={currentSidePanel.currentTab !== "Audio"}
                                    droppableId={EDroppableContainerId.HOTSPOT_FEEDBACK_AUDIO_DROP}
                                />
                                <Grid
                                    xs={12}
                                    container
                                    item
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    marginBottom={"5px"}
                                >
                                    <Typography>Drop and drag an audio from the audio sidebar</Typography>
                                </Grid>
                            </Grid>

                            {/* --------------------------------------------------------------- */}
                            {/* Feedback audio */}

                            <Grid item container xs={10}>
                                <Grid item>
                                    <VTTypography>Question for user input</VTTypography>
                                </Grid>
                                {/*-------------------------------------------------------------------------------*/}
                                {/* Preview image */}
                                <Grid item xs={12}>
                                    <VTTextField
                                        value={actionFeedbackInput}
                                        onChange={(e) => setActionFeedbackInput(e)}
                                        multiline
                                        rows={2}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container xs={10} justifyContent={"space-evenly"} alignItems={"center"}>
                                {currentQuestion &&
                                    currentQuestion.contents?.preClickContent?.question?.answers &&
                                    currentQuestion.contents?.preClickContent?.question?.answers.includes(
                                        currentHotspot.currentHotspot!,
                                    ) && (
                                        <>
                                            <Grid item>
                                                <VTButton
                                                    type={actionProceed ? "primary" : "underline"}
                                                    onClick={() => setActionProceed(!actionProceed)}
                                                    endIcon={actionProceed ? <DoneRoundedIcon /> : <CloseRoundedIcon />}
                                                >
                                                    Proceed video
                                                </VTButton>
                                            </Grid>

                                            {actionProceed && (
                                                <Grid item>
                                                    <VTTypography>Delay Video Proceed</VTTypography>
                                                    <TextField
                                                        type={"number"}
                                                        value={actionFeedbackDelay}
                                                        onChange={(e) => {
                                                            if (!(parseInt(e.target.value) < 0)) {
                                                                setActionFeedbackDelay(parseInt(e.target.value));
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                        </>
                                    )}
                            </Grid>
                        </>
                    )}

                    {currentEditTab === "render" && (
                        <Grid
                            item
                            container
                            xs={10}
                            columnSpacing={1}
                            sx={{
                                marginTop: "5px",
                                overflowY: "scroll",
                                padding: "20px",
                                height: "calc(100vh - 400px)",
                            }}
                            alignContent={"flex-start"}
                        >
                            <>
                                <Grid xs={12} sx={{ marginBlock: "10px" }}>
                                    <VTTypography>
                                        Select hotspot to to be clicked before this hotspot is displayed
                                    </VTTypography>
                                </Grid>
                                {videoCurrentState === "Question" ? (
                                    <>
                                        {questionHotspots &&
                                            currentHotspot.currentHotspot &&
                                            questionHotspots
                                                ?.filter(
                                                    (filterElement: IHotspot) =>
                                                        filterElement.id !== currentHotspot.currentHotspot!.id &&
                                                        !filterElement.actions?.movement?.sceneId &&
                                                        !filterElement.actions?.appFeedback?.proceed,
                                                )
                                                .map((hotspotElement: IHotspot) => (
                                                    <Tooltip
                                                        title={
                                                            actionRenderConditions.includes(hotspotElement.id)
                                                                ? "Click to remove from list"
                                                                : "Click to add to list"
                                                        }
                                                    >
                                                        <Grid
                                                            item
                                                            container
                                                            sx={{
                                                                marginBottom: "8px",
                                                                padding: "10px",
                                                                cursor: "pointer",
                                                                width: "200px",
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    actionRenderConditions.includes(hotspotElement.id)
                                                                ) {
                                                                    setActionRenderConditions([
                                                                        ...actionRenderConditions.filter(
                                                                            (element: string) =>
                                                                                element !== hotspotElement.id,
                                                                        ),
                                                                    ]);
                                                                } else {
                                                                    setActionRenderConditions([
                                                                        ...actionRenderConditions,
                                                                        hotspotElement.id,
                                                                    ]);
                                                                }
                                                            }}
                                                        >
                                                            <VTSidePanelHotspotWrapper
                                                                sx={
                                                                    actionRenderConditions.includes(hotspotElement.id)
                                                                        ? {
                                                                              width: "100%",
                                                                              height: "180px",
                                                                              borderRadius: "16px",
                                                                              boxShadow: "0 0 18px #fff",
                                                                          }
                                                                        : {
                                                                              width: "100%",
                                                                              height: "180px",
                                                                              borderRadius: "16px",
                                                                              transition: "0.5s",
                                                                          }
                                                                }
                                                                title={"Scene Hotspot"}
                                                            >
                                                                <VTHTMLHotspot
                                                                    // icon="movement"
                                                                    onClose={undefined}
                                                                    scale2d={
                                                                        hotspotElement.contents.preClickContent
                                                                            .contentData
                                                                            ? 0.3
                                                                            : 0.65
                                                                    }
                                                                    sx={{
                                                                        "> div:nth-child(1) > div": {
                                                                            border: `3px solid ${
                                                                                hotspotElement.styles?.preClickStyles
                                                                                    ?.colours?.border &&
                                                                                `rgba(${hotspotElement.styles?.preClickStyles?.colours?.border.r}, ${hotspotElement.styles?.preClickStyles?.colours?.border.g}, ${hotspotElement.styles?.preClickStyles?.colours?.border.b}, ${hotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                                                            }`,
                                                                            background: `${
                                                                                hotspotElement.styles?.preClickStyles
                                                                                    ?.colours?.background
                                                                                    ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.background.r}, ${hotspotElement.styles?.preClickStyles?.colours?.background.g}, ${hotspotElement.styles?.preClickStyles?.colours?.background.b}, ${hotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                                                                    : "rgba(30,30,30,0.5)"
                                                                            } `,
                                                                            h3: {
                                                                                color: `${
                                                                                    hotspotElement.styles
                                                                                        ?.preClickStyles?.colours?.text
                                                                                        ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                        : "#fff"
                                                                                } !important`,
                                                                            },
                                                                        },
                                                                        "> div:nth-child(2) > div": {
                                                                            background: `${
                                                                                hotspotElement.styles?.preClickStyles
                                                                                    ?.colours?.background
                                                                                    ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.background.r}, ${hotspotElement.styles?.preClickStyles?.colours?.background.g}, ${hotspotElement.styles?.preClickStyles?.colours?.background.b}, ${hotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                                                                    : "rgba(30,30,30,0.5)"
                                                                            } `,
                                                                            border: `3px solid ${
                                                                                hotspotElement.styles?.preClickStyles
                                                                                    ?.colours?.border &&
                                                                                `rgba(${hotspotElement.styles?.preClickStyles?.colours?.border.r}, ${hotspotElement.styles?.preClickStyles?.colours?.border.g}, ${hotspotElement.styles?.preClickStyles?.colours?.border.b}, ${hotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                                                            }`,
                                                                            div: {
                                                                                color: `${
                                                                                    hotspotElement.styles
                                                                                        ?.preClickStyles?.colours?.text
                                                                                        ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                        : "#fff"
                                                                                }`,
                                                                            },
                                                                        },
                                                                    }}
                                                                    title={
                                                                        hotspotElement.contents.preClickContent.title
                                                                    }
                                                                    icon={
                                                                        checkIconExistsInIconWrapper(hotspotElement.contents.preClickContent.icon) ? (
                                                                            <IconWrapper
                                                                                iconName={
                                                                                    hotspotElement.contents
                                                                                        .preClickContent
                                                                                        .icon as IconName
                                                                                }
                                                                                sx={{
                                                                                    height: "80px",
                                                                                    width: "80px",
                                                                                    color: `${
                                                                                        hotspotElement.styles
                                                                                            ?.preClickStyles?.colours
                                                                                            ?.text
                                                                                            ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                            : "#fff"
                                                                                    }`,
                                                                                }}
                                                                            />
                                                                        ) : undefined
                                                                    }
                                                                    vtRichTextEditorContent={
                                                                        hotspotElement.contents.preClickContent
                                                                            .contentData &&
                                                                        typeof hotspotElement.contents.preClickContent
                                                                            .contentData === "string" &&
                                                                        hotspotElement.contents.preClickContent.contentData.replace(
                                                                            /\s+/g,
                                                                            "",
                                                                        ) !== `<divclass="rte-container"></div>`
                                                                            ? {
                                                                                  content:
                                                                                      hotspotElement.contents
                                                                                          .preClickContent.contentData,
                                                                              }
                                                                            : undefined
                                                                    }
                                                                />
                                                            </VTSidePanelHotspotWrapper>
                                                        </Grid>
                                                    </Tooltip>
                                                ))}
                                    </>
                                ) : (
                                    <>
                                        {hotspots &&
                                            currentHotspot.currentHotspot &&
                                            hotspots
                                                ?.filter(
                                                    (filterElement: IHotspot) =>
                                                        filterElement.id !== currentHotspot.currentHotspot!.id &&
                                                        !filterElement.actions?.movement?.sceneId &&
                                                        filterElement.type !== EDraggableOptions.QUESTION,
                                                )
                                                .map((hotspotElement: IHotspot) => (
                                                    <Tooltip
                                                        title={
                                                            actionRenderConditions.includes(hotspotElement.id)
                                                                ? "Click to remove from list"
                                                                : "Click to add to list"
                                                        }
                                                    >
                                                        <Grid
                                                            item
                                                            container
                                                            sx={{
                                                                marginBottom: "8px",
                                                                padding: "10px",
                                                                cursor: "pointer",
                                                                width: "200px",
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    actionRenderConditions.includes(hotspotElement.id)
                                                                ) {
                                                                    setActionRenderConditions([
                                                                        ...actionRenderConditions.filter(
                                                                            (element: string) =>
                                                                                element !== hotspotElement.id,
                                                                        ),
                                                                    ]);
                                                                } else {
                                                                    setActionRenderConditions([
                                                                        ...actionRenderConditions,
                                                                        hotspotElement.id,
                                                                    ]);
                                                                }
                                                            }}
                                                        >
                                                            <VTSidePanelHotspotWrapper
                                                                sx={
                                                                    actionRenderConditions.includes(hotspotElement.id)
                                                                        ? {
                                                                              width: "100%",
                                                                              height: "180px",
                                                                              borderRadius: "16px",
                                                                              boxShadow: "0 0 18px #fff",
                                                                          }
                                                                        : {
                                                                              width: "100%",
                                                                              height: "180px",
                                                                              borderRadius: "16px",
                                                                              transition: "0.5s",
                                                                          }
                                                                }
                                                                title={"Scene Hotspot"}
                                                            >
                                                                <VTHTMLHotspot
                                                                    // icon="movement"
                                                                    onClose={undefined}
                                                                    scale2d={
                                                                        hotspotElement.contents.preClickContent
                                                                            .contentData
                                                                            ? 0.3
                                                                            : 0.65
                                                                    }
                                                                    sx={{
                                                                        "> div:nth-child(1) > div": {
                                                                            marginTop: hotspotElement.contents
                                                                                .preClickContent.contentData
                                                                                ? "-80px"
                                                                                : 0,
                                                                            border: `3px solid ${
                                                                                hotspotElement.styles?.preClickStyles
                                                                                    ?.colours?.border &&
                                                                                `rgba(${hotspotElement.styles?.preClickStyles?.colours?.border.r}, ${hotspotElement.styles?.preClickStyles?.colours?.border.g}, ${hotspotElement.styles?.preClickStyles?.colours?.border.b}, ${hotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                                                            }`,
                                                                            background: `${
                                                                                hotspotElement.styles?.preClickStyles
                                                                                    ?.colours?.background
                                                                                    ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.background.r}, ${hotspotElement.styles?.preClickStyles?.colours?.background.g}, ${hotspotElement.styles?.preClickStyles?.colours?.background.b}, ${hotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                                                                    : "rgba(30,30,30,0.5)"
                                                                            } `,
                                                                            h3: {
                                                                                color: `${
                                                                                    hotspotElement.styles
                                                                                        ?.preClickStyles?.colours?.text
                                                                                        ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                        : "#fff"
                                                                                } !important`,
                                                                            },
                                                                        },
                                                                        "> div:nth-child(2) > div": {
                                                                            background: `${
                                                                                hotspotElement.styles?.preClickStyles
                                                                                    ?.colours?.background
                                                                                    ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.background.r}, ${hotspotElement.styles?.preClickStyles?.colours?.background.g}, ${hotspotElement.styles?.preClickStyles?.colours?.background.b}, ${hotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                                                                    : "rgba(30,30,30,0.5)"
                                                                            } `,
                                                                            border: `3px solid ${
                                                                                hotspotElement.styles?.preClickStyles
                                                                                    ?.colours?.border &&
                                                                                `rgba(${hotspotElement.styles?.preClickStyles?.colours?.border.r}, ${hotspotElement.styles?.preClickStyles?.colours?.border.g}, ${hotspotElement.styles?.preClickStyles?.colours?.border.b}, ${hotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                                                            }`,
                                                                            div: {
                                                                                color: `${
                                                                                    hotspotElement.styles
                                                                                        ?.preClickStyles?.colours?.text
                                                                                        ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                        : "#fff"
                                                                                }`,
                                                                            },
                                                                        },
                                                                    }}
                                                                    title={
                                                                        hotspotElement.contents.preClickContent.title
                                                                    }
                                                                    icon={
                                                                        checkIconExistsInIconWrapper(hotspotElement.contents.preClickContent.icon) ? (
                                                                            <IconWrapper
                                                                                iconName={
                                                                                    hotspotElement.contents
                                                                                        .preClickContent
                                                                                        .icon as IconName
                                                                                }
                                                                                sx={{
                                                                                    height: "80px",
                                                                                    width: "80px",
                                                                                    color: `${
                                                                                        hotspotElement.styles
                                                                                            ?.preClickStyles?.colours
                                                                                            ?.text
                                                                                            ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                            : "#fff"
                                                                                    }`,
                                                                                }}
                                                                            />
                                                                        ) : undefined
                                                                    }
                                                                    vtRichTextEditorContent={
                                                                        hotspotElement.contents.preClickContent
                                                                            .contentData &&
                                                                        typeof hotspotElement.contents.preClickContent
                                                                            .contentData === "string" &&
                                                                        hotspotElement.contents.preClickContent.contentData.replace(
                                                                            /\s+/g,
                                                                            "",
                                                                        ) !== `<divclass="rte-container"></div>`
                                                                            ? {
                                                                                  content:
                                                                                      hotspotElement.contents
                                                                                          .preClickContent.contentData,
                                                                              }
                                                                            : Array.isArray(
                                                                                  hotspotElement.contents
                                                                                      .preClickContent?.contentData,
                                                                              )
                                                                            ? {
                                                                                  content:
                                                                                      hotspotElement.contents
                                                                                          .preClickContent?.contentData,
                                                                                  width: hotspotElement.contents
                                                                                      .preClickContent
                                                                                      ?.presentationModeContentWidthHeight
                                                                                      ?.w
                                                                                      ? hotspotElement.contents
                                                                                            .preClickContent
                                                                                            ?.presentationModeContentWidthHeight
                                                                                            .w
                                                                                      : 350,
                                                                                  height: hotspotElement.contents
                                                                                      .preClickContent
                                                                                      ?.presentationModeContentWidthHeight
                                                                                      ?.h
                                                                                      ? hotspotElement.contents
                                                                                            .preClickContent
                                                                                            ?.presentationModeContentWidthHeight
                                                                                            .h
                                                                                      : 220,
                                                                              }
                                                                            : undefined
                                                                    }
                                                                />
                                                            </VTSidePanelHotspotWrapper>
                                                        </Grid>
                                                    </Tooltip>
                                                ))}
                                    </>
                                )}
                            </>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </VTDialog>
    );
}

//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Material ui Imports
// Custom components imports (and hooks and helpers)
import {
    CurrentPage,
    EditHotSpotDialogTab,
    ICurrentHotspot,
    ICurrentPresentationModeDataToDropObject,
    ICurrentSidePanel,
    ICurrentState,
} from "../../models/current.models";
import { uploadMedia } from "../thunks/upload.thunks";
import { ScenarioApi } from "../../services/scenario.service";
import {
    IScenario,
    IScenarioOverview,
    IScene,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IVTRichTextEditorPresentationModeCurrentlyDraggingObj } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTHotspotContentCreator/VTRichTextEditorPresentationMode";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
const currentInitialState: ICurrentState = {
    currentScenario: {} as IScenario,
    currentScene: {} as IScene,
    currentHotspot: { currentHotspot: undefined, open: false },
    currentSidePanel: { currentTab: undefined, currentWidth: 0, option: undefined },
    currentQuestion: {} as IHotspot,
    currentlyDragging: undefined,
    currentAttachedHotspot: {} as IHotspot,
    currentAudio: "",
    currentFeedbackImage: "",
    currentFeedbackVideo: "",
    currentFeedbackInput: "",
    currentLookAt: undefined,
    //TODO; Replace uses with currentHotpsot
    editHotspotDialogIsCurrentlyOpen: false,
    editHotspotDialogTabCurrentlyOpen: undefined,
    editHotspotDialogMediaTransfer: undefined,
    currentPage: undefined,
    hotspotAdded: undefined,
    currentToast: undefined,
    currentSettingStartLocation: false,
    currentlyPreview: false,
    currentScenarioId: undefined,
    currentScenarioIsUpdating: false,
    currentDoubleClick: undefined,
    currentPreviewImage: "",
    questionAudioAdd: undefined,
    currentScenarioName: "",
    currentHotspotTransfer: undefined,
    currentCreatorScenesTimelineShouldScrollToEnd: false,
    currentRichTextEditorNormalModeId: undefined,
    currentRichTextEditorMode: undefined,
    currentRichTextEditorPresentationModeDropOffsetsCoords: undefined,
    currentRichTextEditorPresentationModeDataToDropObject: undefined,
    currentSidePannelDesiredWidth: 350,
};

const currentReducer = createSlice({
    name: "current",
    initialState: currentInitialState,
    reducers: {
        setCurrentScene: (state: ICurrentState, { payload }: PayloadAction<IScene>) => {
            state.currentScene = payload;
        },
        setCurrentPreviewImage: (state: ICurrentState, { payload }: PayloadAction<string>) => {
            state.currentPreviewImage = payload;
        },

        setCurrentScenarioId: (state: ICurrentState, { payload }: PayloadAction<string>) => {
            state.currentScenarioId = payload;
        },

        setCurrentPage: (state: ICurrentState, { payload }: PayloadAction<CurrentPage>) => {
            state.currentPage = payload;
        },
        setCurrentHotspot: (state: ICurrentState, { payload }: PayloadAction<ICurrentHotspot>) => {
            state.currentHotspot = payload;
        },
        setCurrentSidePanel: (state: ICurrentState, { payload }: PayloadAction<ICurrentSidePanel>) => {
            state.currentSidePanel = payload;
        },
        setCurrentQuestion: (state: ICurrentState, { payload }: PayloadAction<IHotspot | undefined>) => {
            if (payload) {
                state.currentQuestion = payload;
            }
        },
        setCurrentDragging: (state: ICurrentState, { payload }: PayloadAction<IDragOptions | undefined>) => {
            state.currentlyDragging = payload;
        },

        setCurrentAttachedHotspot: (state: ICurrentState, { payload }: PayloadAction<IHotspot>) => {
            state.currentAttachedHotspot = payload;
        },

        setCurrentAudio: (state: ICurrentState, { payload }: PayloadAction<string>) => {
            state.currentAudio = payload;
        },
        setCurrentFeedbackImage: (state: ICurrentState, { payload }: PayloadAction<string>) => {
            state.currentFeedbackImage = payload;
        },
        setCurrentFeedbackVideo: (state: ICurrentState, { payload }: PayloadAction<string>) => {
            state.currentFeedbackVideo = payload;
        },
        setCurrentFeedbackInput: (state: ICurrentState, { payload }: PayloadAction<string>) => {
            state.currentFeedbackInput = payload;
        },
        setCurrentLookAt: (state: ICurrentState, { payload }: PayloadAction<number[] | undefined>) => {
            state.currentLookAt = payload;
        },
        setEditHotspotDialogIsCurrentlyOpen: (state: ICurrentState, { payload }: PayloadAction<boolean>) => {
            state.editHotspotDialogIsCurrentlyOpen = payload;
        },
        setEditHotspotDialogTabCurrentlyOpen: (
            state: ICurrentState,
            { payload }: PayloadAction<EditHotSpotDialogTab | undefined>,
        ) => {
            state.editHotspotDialogTabCurrentlyOpen = payload;
        },
        setEditHotspotDialogMediaTransfer: (
            state: ICurrentState,
            { payload }: PayloadAction<{ src: string; type: EDraggableOptions } | undefined>,
        ) => {
            state.editHotspotDialogMediaTransfer = payload;
        },
        setHotspotAdded: (state: ICurrentState, { payload }: PayloadAction<IHotspot | undefined>) => {
            state.hotspotAdded = payload;
        },
        setCurrentToast: (
            state: ICurrentState,
            { payload }: PayloadAction<{ id: string; message?: string; time?: number; component?: any } | undefined>,
        ) => {
            state.currentToast = payload;
        },
        setCurrentStartingLocation: (state: ICurrentState, { payload }: PayloadAction<boolean>) => {
            state.currentSettingStartLocation = payload;
        },
        setCurrentlyPreview: (state: ICurrentState, { payload }: PayloadAction<boolean>) => {
            state.currentlyPreview = payload;
        },
        setCurrentDoubleClick: (state: ICurrentState, { payload }: PayloadAction<IDragOptions | undefined>) => {
            state.currentDoubleClick = payload;
        },
        setQuestionAudioAdd: (state: ICurrentState, { payload }: PayloadAction<string | undefined>) => {
            state.questionAudioAdd = payload;
        },
        setCurrentScenarioName: (state: ICurrentState, { payload }: PayloadAction<string>) => {
            state.currentScenarioName = payload;
        },
        setCurrentScenario: (state: ICurrentState, { payload }: PayloadAction<IScenario>) => {
            state.currentScenario = payload;
        },
        setCurrentHotspotTransfer: (
            state: ICurrentState,
            { payload }: PayloadAction<{ create: boolean; hotspot: IHotspot } | undefined>,
        ) => {
            state.currentHotspotTransfer = payload;
        },
        setCurrentCreatorScenesTimelineShouldScrollToEnd: (
            state: ICurrentState,
            { payload }: PayloadAction<boolean>,
        ) => {
            state.currentCreatorScenesTimelineShouldScrollToEnd = payload;
        },
        setCurrentRichTextEditorNormalModeId: (
            state: ICurrentState,
            { payload }: PayloadAction<string | undefined>,
        ) => {
            state.currentRichTextEditorNormalModeId = payload;
        },
        setCurrentRichTextEditorMode: (
            state: ICurrentState,
            { payload }: PayloadAction<"presentation mode" | "normal mode" | undefined>,
        ) => {
            state.currentRichTextEditorMode = payload;
        },
        setCurrentRichTextEditorPresentationModeDropOffsetsCoords: (
            state: ICurrentState,
            { payload }: PayloadAction<{ x: number; y: number } | undefined>,
        ) => {
            state.currentRichTextEditorPresentationModeDropOffsetsCoords = payload;
        },
        setCurrentRichTextEditorPresentationModeDataToDropObject: (
            state: ICurrentState,
            { payload }: PayloadAction<IVTRichTextEditorPresentationModeCurrentlyDraggingObj | undefined>,
        ) => {
            state.currentRichTextEditorPresentationModeDataToDropObject = payload;
        },
        setCurrentSidePanelDesiredWidth: (state: ICurrentState, { payload }: PayloadAction<number>) => {
            state.currentSidePannelDesiredWidth = payload;
        },
    },
    extraReducers(builder) {
        builder.addMatcher(ScenarioApi.endpoints.updateScenario.matchPending, (state: ICurrentState) => {
            state.currentScenarioIsUpdating = true;
        });
        builder.addMatcher(ScenarioApi.endpoints.updateScenario.matchFulfilled, (state: ICurrentState) => {
            state.currentScenarioIsUpdating = false;
        });
    },
});

export const {
    setCurrentScenarioId,
    setCurrentScene,
    setCurrentHotspot,
    setCurrentQuestion,
    setCurrentDragging,
    setCurrentSidePanel,
    setCurrentAttachedHotspot,
    setCurrentAudio,
    setEditHotspotDialogIsCurrentlyOpen,
    setEditHotspotDialogTabCurrentlyOpen,
    setCurrentFeedbackImage,
    setCurrentFeedbackVideo,
    setCurrentFeedbackInput,
    setCurrentLookAt,
    setEditHotspotDialogMediaTransfer,
    setCurrentPage,
    setHotspotAdded,
    setCurrentToast,
    setCurrentStartingLocation,
    setCurrentlyPreview,
    setCurrentDoubleClick,
    setCurrentPreviewImage,
    setQuestionAudioAdd,
    setCurrentScenarioName,
    setCurrentScenario,
    setCurrentHotspotTransfer,
    setCurrentCreatorScenesTimelineShouldScrollToEnd,
    setCurrentRichTextEditorNormalModeId,
    setCurrentRichTextEditorMode,
    setCurrentRichTextEditorPresentationModeDropOffsetsCoords,
    setCurrentRichTextEditorPresentationModeDataToDropObject,
    setCurrentSidePanelDesiredWidth,
} = currentReducer.actions;

export default currentReducer.reducer;
